import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

import { AsyncUserState } from '../types'
import { JukeboxClient } from '../JukeboxClient'
import { Container } from '../components/Common';

interface State {
    readonly user: AsyncUserState;
    readonly spotifyToken?: any; // TEMP
}

export class MyAccountView extends React.PureComponent<undefined, State> {

    constructor(props: undefined) {
        super(props);

        this.state = { user: { isPending: false }};
    }

    UNSAFE_componentWillMount() {
        JukeboxClient.instance.getAccountInfo()
        .then(data => {
            this.setState({...this.state, user: {
                isPending: false,
                data: data
            }})
        })
        .then(() => JukeboxClient.instance.getSpotifyToken())
        .then(t => {
            this.setState({...this.state,
                spotifyToken: t
            })
        })
        .catch(err => {
            this.setState({...this.setState, user: {
                isPending: false,
                error: err }})
        })
    }

    render() {
        if (this.state.user.error) {
            const errorText = typeof(this.state.user.error) == 'object' ? this.state.user.error.message : this.state.user.error;
            return (
                <div>
                    <p>An error occurred: {errorText}</p>
                </div>
            );
        }

        if (this.state.user.isPending) {
            return <CircularProgress />;
        }

        return (
            <Container>
                <h1>Account info: </h1>
                <pre>{JSON.stringify(this.state.user.data, null, 2)}</pre>
                <h1>Spotify token: </h1>
                <pre>{JSON.stringify(this.state.spotifyToken, null, 2)}</pre>
            </Container>
        );
    }

}