import * as React from "react";
import { connect } from 'react-redux';
import styled from "@emotion/styled";

import { IconButton, Icon, Fab, Tooltip } from "@mui/material";
import { SyncController } from "../SyncController";
import { JukeboxStatus, JukeboxInfo, StoreType } from "../types";
import log from "../log";
import { VolumeControl } from "./VolumeControl";

export interface Props {
    jukebox: JukeboxInfo;
    syncController: SyncController;
    tracksLoaded: boolean;
    isLoggedIn: boolean;
}

class PlaybackControlsImpl extends React.PureComponent<Props> {

    componentDidMount() {
        document.addEventListener("fullscreenchange", this.handleFullScreenChange);
    }

    componentWillUnmount() {
        document.removeEventListener("fullscreenchange", this.handleFullScreenChange);
    }

    render() {
        const { syncController, tracksLoaded, isLoggedIn } = this.props;
        if (!syncController || !tracksLoaded || !isLoggedIn)
            return null;

        const actionInfo = this.getMainActionInfo();
        const fullScreen = this.fullScreenInfo();
        return (
            <ControlButtonContainer className="playback-controls">
                {this.props.jukebox.status === JukeboxStatus.Running &&
                    <Tooltip title="Skip to next track" aria-label="Skip to next track">
                        <IconButton onClick={this.onSkipButtonClick} color="primary">
                            <Icon>
                                skip_next
                            </Icon>
                        </IconButton>
                    </Tooltip>
                }
                <Fab
                    variant="extended"
                    onClick={this.onMainButtonClick}
                    color="primary"
                    size="small"
                >
                    <Icon>{actionInfo.icon}</Icon>
                    {actionInfo.text}
                </Fab>
                <VolumeControl />
                {fullScreen.enabled &&
                    <Tooltip title={fullScreen.tooltip} aria-label={fullScreen.tooltip}>
                        <IconButton onClick={this.onFullScreenButtonClick} color="primary">
                            <Icon>
                                {fullScreen.icon}
                            </Icon>
                        </IconButton>
                    </Tooltip>
                }
            </ControlButtonContainer>
        );
    }

    private onMainButtonClick = () => {
        if (this.props.jukebox.status === JukeboxStatus.Running) {
            this.props.syncController.addCommand('pause');
        }
        else {
            this.props.syncController.addCommand('start');
        }
    }

    private onSkipButtonClick = () => {
        if (this.props.jukebox.status !== JukeboxStatus.Running)
            return;

        this.props.syncController.addCommand('next');
    }

    private onFullScreenButtonClick = async () => {
        if (!document.fullscreenEnabled)
            return;

        try {
            if (!document.fullscreenElement) {
                await document.documentElement.requestFullscreen({navigationUI: "hide"});
            }
            else {
                await document.exitFullscreen();
            }
        }
        catch(e) {
            log.debug("error with full screen", e);
        }
    }

    private handleFullScreenChange = () => {
        this.forceUpdate();
    }

    private fullScreenInfo = () => ({
        enabled: document.fullscreenEnabled,
        isFullScreen: !!document.fullscreenElement,
        icon: document.fullscreenElement ? "fullscreen_exit" : "fullscreen",
        tooltip: document.fullscreenElement ? "Exit full screen" : "Enter full screen",
    })

    private getMainActionInfo() {
        switch (this.props.jukebox.status) {
            case JukeboxStatus.Paused:
                return {
                    icon: 'play_circle_outline',
                    text: 'Resume'
                };
            case JukeboxStatus.Running:
                return {
                    icon: 'pause_circle_outline',
                    text: 'Pause'
                };
        }

        return {
            icon: 'playlist_play',
            text: 'Start'
        };
    }
}


const mapStateToProps = (state: StoreType): Props => ({
    jukebox: state.jukebox,
    syncController: state.app.syncController,
    tracksLoaded: state.tracks.tracks.length > 0,
    isLoggedIn: !!state.account.user.data || state.account.user.isPending,
});
export const PlaybackControls = connect(mapStateToProps)(PlaybackControlsImpl);

const ControlButtonContainer = styled("div")`
`;