import { JukeboxInfo, PlaybackInfo, Track, DeviceInfo, Tracks } from './types'
import { JukeboxClient } from './JukeboxClient'

import { store } from './store'
import { SyncController } from './SyncController';
import * as config from "./config";
import { JukeboxController } from './JukeboxController';
import { getUserVoteCount, getMaxVotesPerUser } from './JukeboxHelper';

export const ACTION_SET_TRACKS = "TRACKS";
export const ACTION_ADD_TRACK = "TRACK_ADD";
export const ACTION_REMOVE_TRACK = "TRACK_REMOVE";
export const ACTION_UPDATE_TRACK = "TRACK_UPDATE";

export const ACTION_PLAYBACK_INFO = "PLAYBACK_INFO";
export const ACTION_DEVICE_INFO = "DEVICE_INFO";
export const ACTION_JUKEBOX_STATUS = "JUKEBOX_STATUS";
export const ACTION_JUKEBOX_INFO = "SET_JUKEBOX_INFO";

export const ACTION_ACCOUNT_INFO = "ACCOUNT";
export const ACTION_JUKEBOX_LIST = "JUKEBOX_LIST";
export const ACTION_VOTER_ID = "VOTER_ID";
export const ACTION_POST_VOTE = "POST_VOTE";

export const ACTION_SYNC_CONTROLLER = "SYNC_CONTROLLER";
export const ACTION_JUKEBOX_CONTROLLER = "JUKEBOX_CONTROLLER";

export function setPlaybackInfo(pi: PlaybackInfo) {
    return store.dispatch({
        type: ACTION_PLAYBACK_INFO,
        payload: pi
    })
}

export function setTracks(tracks: Tracks) {
    return store.dispatch({
        type: ACTION_SET_TRACKS,
        payload: tracks
    })
}

export function addTrack(track: Track) {
    return store.dispatch({
        type: ACTION_ADD_TRACK,
        payload: track
    })
}

export function removeTrack(trackSyncIndex: number) {
    return store.dispatch({
        type: ACTION_REMOVE_TRACK,
        payload: trackSyncIndex
    })
}

export function updateTrack(track: Track) {
    return store.dispatch({
        type: ACTION_UPDATE_TRACK,
        payload: track
    })
}

export function setDeviceInfo(di: DeviceInfo) {
    return store.dispatch({
        type: ACTION_DEVICE_INFO,
        payload: di
    })
}

export function setJukeboxInfo(ji: JukeboxInfo) {
    return store.dispatch({
        type: ACTION_JUKEBOX_INFO,
        payload: ji
    })
}

export function getAccountInfo() {
    if (!isLoggedIn()) {
        return;
    }

    return store.dispatch({
        type: ACTION_ACCOUNT_INFO,
        payload: JukeboxClient.instance.getAccountInfo()
    })
}

export function getVoterId(jukeboxId: string) {
    return store.dispatch({
        type: ACTION_VOTER_ID,
        payload: JukeboxClient.instance.getVoterId(jukeboxId)
    });
}

export function setVoterId(voterId: string) {
    return store.dispatch({
        type: ACTION_VOTER_ID,
        payload: Promise.resolve(voterId)
    });
}

export function getJukeboxList() {
    return store.dispatch({
        type: ACTION_JUKEBOX_LIST,
        payload: JukeboxClient.instance.getJukeboxes()
    })
}

export function logOut() {
    window.localStorage.removeItem(LOGIN_KEY);
    return JukeboxClient.instance.logOut()
        .then(() => {
            store.dispatch({
                type: ACTION_ACCOUNT_INFO,
                payload: Promise.resolve(undefined)
            })
        });
}

const canVote = (user: string, up: boolean): boolean => {
    const tracks = store.getState().tracks.tracks;
    const jukebox = store.getState().jukebox;

    return getUserVoteCount(user, up, tracks) < getMaxVotesPerUser(jukebox);
}

export function postVote(jukeboxId: string, trackIndex: number, up: boolean) {
    const user = store.getState().account.voterId.data;
    if (!canVote(user, up)) {
        return;
    }

    store.dispatch({
        type: ACTION_POST_VOTE,
        payload: {
            jukeboxId,
            trackIndex: trackIndex - 1,
            up,
            voterId: store.getState().account.voterId.data
        }
    });
    return JukeboxClient.instance.postVote(jukeboxId, trackIndex, up);
}

export function setSyncController(syncController: SyncController) {
    return store.dispatch({
        type: ACTION_SYNC_CONTROLLER,
        payload: syncController
    });
}

export function setJukeboxController(jukeboxController: JukeboxController) {
    return store.dispatch({
        type: ACTION_JUKEBOX_CONTROLLER,
        payload: jukeboxController
    });
}

const LOGIN_KEY = "LOGIN_INFO";
export function redirectToLogin() {
    window.localStorage.setItem(LOGIN_KEY, "INVOKED");
    window.location.href = config.SERVICE_BASE_URL + 'auth/spotify';
}

function isLoggedIn() {
    return !!window.localStorage.getItem(LOGIN_KEY);
}