import * as React from 'react'
import { connect } from 'react-redux'
import { Redirect, Prompt } from 'react-router'
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import * as Actions from '../actions'

import { StoreType, AccountState, JukeboxInfo,  JukeboxStatus, SpotifyState, Tracks } from '../types'

import { SpotifyRequestHandler } from '../SpotifyRequestHandler'
import { JukeboxController } from '../JukeboxController'
import { SyncController } from '../SyncController'

import { Container } from '../components/Common'
import { LeftPane } from '../components/LeftPane'
import { RightPane } from '../components/RightPane'
import { MinimalJukeboxView } from './MinimalJukeboxView'

import { NotificationBar } from '../components/NotificationBar';
import { Typography } from '@mui/material';
import { ConfirmDialog } from '../components/ConfirmDialog';
import log from "../log";
import { DebouncedRender } from '../components/DebouncedRender';
import { JukeboxThemeProvider } from '../components/JukeboxThemeProvider';


interface Params {
    jukeboxId?: string;
}

interface Match {
    params?: Params;
}

interface Props extends WithSnackbarProps {
    account: AccountState;
    jukebox: JukeboxInfo;
    tracks: Tracks;
    spotifyState: SpotifyState;
    minimal: boolean;
    match?: Match;
    isMaster?: boolean;
}

interface State {
    isMasterConfirmationNeeded?: boolean;
    canBeMaster?: boolean;
    redirectToPage?: string;
    playlistTracksLoaded?: boolean;
    error?: string;
}

class JukeboxView extends React.PureComponent<Props, State> {

    private jukeboxController: JukeboxController;
    private syncController: SyncController;
    private jukeboxId: string;

    constructor(props: Props) {
        super(props);
        this.state = {};

        this.jukeboxId = props.match.params.jukeboxId;

        if (!this.jukeboxId || this.jukeboxId.length !== 24) {
            log.warn('No phone number or playlist uri set');
            this.state = { redirectToPage: '/setup' };
            return;
        }

        SyncController.get(this.jukeboxId, props.minimal)
            .then(controller => {
                this.syncController = controller;
                this.syncController.loadTracks();

                Actions.setSyncController(controller);
            })
            .then(() => {
                const potentiallyMaster = !props.minimal && this.props.account.user.data && this.props.account.user.data._id === this.props.jukebox.owner;

                let state: State = {
                    playlistTracksLoaded: true,
                    canBeMaster: potentiallyMaster
                };
                if (potentiallyMaster) {
                    if (this.props.jukebox.status === JukeboxStatus.Running || this.props.jukebox.status === JukeboxStatus.Paused) {
                        state.isMasterConfirmationNeeded = true;
                    }
                    else {
                        this.setupMasterController();
                    }
                }

                this.setState(state);
            })
            .catch((err) => {
                log.debug("Jukebox error", err);
                this.setState((state) => ({...state, error: "Unable to access jukebox" }));
            });
    }

    componentDidMount() {
        window.onbeforeunload = () => this.confirmUnload();
    }

    componentWillUnmount() {
        if (this.jukeboxController) {
            this.jukeboxController.cleanup();
        }
    }

    public render() {
        const { error, redirectToPage, playlistTracksLoaded, canBeMaster, isMasterConfirmationNeeded } = this.state;
        const { isMaster } = this.props;

        if (redirectToPage) {
            return <Redirect to={redirectToPage} />
        }

        if (error) {
            return (
                <Container>
                    <Typography variant="body1" color="error">Error occurred: {error}</Typography>
                </Container>
            );
        }

        if (!playlistTracksLoaded || (isMaster && !this.props.spotifyState.deviceInfo)) {
            return (
                <Container>
                    <CircularProgress />
                </Container>
            )
        }

        if (!canBeMaster || this.props.minimal) {
            return(
                <div>
                    <div>
                        <DebouncedRender innerProps={this.props.tracks}>
                            {() => (
                                <MinimalJukeboxView
                                    jukebox={this.props.jukebox}
                                    phoneNumber={this.props.jukebox.phoneNumber}
                                    tracks={this.props.tracks}
                                    jukeboxId={this.jukeboxId}
                                    voterId={this.props.account.voterId.data}
                                />
                            )}
                        </DebouncedRender>
                    </div>
                    <NotificationBar
                        syncController={this.syncController}
                        tracks={this.props.tracks}
                        minimal={this.props.minimal}
                    />
                </div>
            )
        }

        return (
            <DebouncedRender innerProps={this.props.tracks}>
                {() => (
                    <JukeboxThemeProvider jukebox={this.props.jukebox}>
                        <RootContainer className="jukebox-root">
                            <SubContainer className="jukebox-sub">
                                <LeftPane
                                    jukebox={this.props.jukebox}
                                    tracks={this.props.tracks}
                                    jukeboxId={this.jukeboxId}
                                />
                                <RightPane
                                    jukebox={this.props.jukebox}
                                    playbackInfo={this.props.spotifyState.playbackInfo}
                                    tracks={this.props.tracks}
                                    jukeboxId={this.jukeboxId}
                                />
                            </SubContainer>
                            {isMasterConfirmationNeeded && (
                                <ConfirmDialog
                                    onCancel={this.handleCancelMaster}
                                    onConfirm={this.handleConfirmMaster}
                                >
                                    This jukebox either appears to be running or was not shut down properly. Do you want to restart the jukebox?
                                </ConfirmDialog>
                            )}
                            <Prompt
                                when={!this.canNavigateAway()}
                                message={this.getNavigateAwayMessage()}
                            />
                            <NotificationBar
                                tracks={this.props.tracks}
                                syncController={this.syncController}
                            />
                        </RootContainer>
                    </JukeboxThemeProvider>
                )}
            </DebouncedRender>
        );
    }

    private canNavigateAway() {
        return !(!!this.props.isMaster && this.props.jukebox.status === JukeboxStatus.Running);
    }

    private getNavigateAwayMessage() {
        return "Jukebox is running. Are you sure you want to navigate away?";
    }

    private confirmUnload() {
        if (!this.canNavigateAway())
            return this.getNavigateAwayMessage();
    }

    private handleCancelMaster = () => {
        this.setState({
            isMasterConfirmationNeeded: false
        })
    }

    private handleConfirmMaster = () => {
        this.setState({
            isMasterConfirmationNeeded: false,
            playlistTracksLoaded: false
        });

        this.setupMasterController();
    }

    private setupMasterController() {
        SpotifyRequestHandler.create()
        .then(spotifyApi => {
            spotifyApi.addListener("error", this.handlePlayerError);

            return JukeboxController.create(this.syncController, spotifyApi)
                .then(jukebox => {
                    this.jukeboxController = jukebox;

                    this.setState({ playlistTracksLoaded: true });
                });
        })
        .catch(err => {
            log.error("Failed to create spotify API", err);
        });
    }

    private handlePlayerError = (message: string, error: string) => {
        this.props.enqueueSnackbar(`Spotify ${error}: ${message}`, {
            variant: "error",
            preventDuplicate: true
        })
    }
}

const mapStateToProps = (state: StoreType, ownProps: Props): Props => {
    return {
        account: state.account,
        jukebox: state.jukebox,
        tracks: state.tracks.tracks,
        spotifyState: state.spotifyState,
        minimal: ownProps.minimal || false,
        enqueueSnackbar: ownProps.enqueueSnackbar,
        closeSnackbar: ownProps.closeSnackbar,
        isMaster: !!state.app.jukeboxController
    }
}

export default withSnackbar(connect(mapStateToProps)(JukeboxView));

const SubContainer = styled("div")`
    flex: 1;
    display: flex;
    margin: 0;
    text-align: center;
    align-self: stretch;
    overflow: hidden;
`;

const RootContainer = styled(Container)`
    overflow: hidden;
`;