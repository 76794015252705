import { Reducer, AnyAction } from 'redux'

import * as Actions from './actions'

import { TracksState, JukeboxStatus, JukeboxInfo, SpotifyState,
    AccountState,
    AppState} from './types'
import { JukeboxController } from './JukeboxController';

const initialJukeboxInfo: JukeboxInfo = {
    status: JukeboxStatus.NotReady,
    currentTrack: undefined,
    upcomingTrack: undefined,
    phoneNumber: undefined,
    playlistName: undefined,
    owner: undefined,
    shortUrl: undefined,
    lastTrackIndex: 0
};

const initialSpotifyState: SpotifyState = { connected: false };

export const JukeboxInfoReducer: Reducer<JukeboxInfo> =
    (state: JukeboxInfo = initialJukeboxInfo, action: AnyAction): JukeboxInfo => {

    switch (action.type) {
        case Actions.ACTION_JUKEBOX_STATUS: {
            return {...state, status: action.payload };
        }

        case Actions.ACTION_JUKEBOX_INFO: {
            return action.payload;
        }
    }

    return state;
}


export const SpotifyReducer: Reducer<SpotifyState> =
(state: SpotifyState = initialSpotifyState, action: AnyAction): SpotifyState => {

switch (action.type) {
    case Actions.ACTION_DEVICE_INFO: {
        return {...state, deviceInfo: action.payload };
    }

    case Actions.ACTION_PLAYBACK_INFO: {
        return {...state, playbackInfo: action.payload };
    }
}

return state;
}

const initialTracks: TracksState = { tracks: [] };
export const TracksReducer: Reducer<TracksState> =
(state: TracksState = initialTracks, action: AnyAction): TracksState => {
    switch (action.type) {
        case Actions.ACTION_SET_TRACKS: {
            return {...state, tracks: JukeboxController.getSortedTracksBasedOnVotes(action.payload) };
        }
        case Actions.ACTION_ADD_TRACK: {
            return {...state, tracks: JukeboxController.getSortedTracksBasedOnVotes(
                state.tracks.concat(action.payload)
            )};
        }
        case Actions.ACTION_UPDATE_TRACK: {
            return {...state, tracks: JukeboxController.getSortedTracksBasedOnVotes(
                state.tracks.map(t => t.syncIndex === action.payload.syncIndex ? action.payload : t)
            )};
        }
        case Actions.ACTION_POST_VOTE: {
            const {
                trackIndex,
                up,
                voterId
            } = action.payload;

            const track = state.tracks.find(t => t.jukeboxIndex === trackIndex);
            if (!track)
                return state;

            const voterExists = track.voters[up ? 'up' : 'down'].find(v => v === voterId);
            if (voterExists)
                return state;

            if (up) {
                track.voters.up = track.voters.up.concat(voterId);
            } else {
                track.voters.down = track.voters.down.concat(voterId);
            }

            track.voters = {...track.voters};

            const newTrack = {...track, lastVoteTimestamp: new Date().getTime()}
            return {...state, tracks: JukeboxController.getSortedTracksBasedOnVotes(
                state.tracks.map(t => t.jukeboxIndex === trackIndex ? newTrack : t)
            )};
        }
        case Actions.ACTION_REMOVE_TRACK: {
            return {...state, tracks: JukeboxController.getSortedTracksBasedOnVotes(
                state.tracks.filter(t => t.syncIndex !== action.payload)
            )};
        }
    }

    return state;
}

const initialAccountState: AccountState = {
    user: {
        isPending: false
    },
    jukeboxes: {
        isPending: false
    },
    voterId: {
        isPending: false
    }
};
export const AccountReducer: Reducer<AccountState> =
(state: AccountState = initialAccountState, action: AnyAction): AccountState => {
    switch (action.type) {
        case `${Actions.ACTION_ACCOUNT_INFO}_FULFILLED`: {
            return {...state,
                user: {...state.user,
                    isPending: false,
                    data: action.payload,
                    error: undefined
                }};
        }
        case `${Actions.ACTION_ACCOUNT_INFO}_PENDING`: {
            return {...state,
                user: {...state.user,
                    isPending: true
                }};
        }

        case `${Actions.ACTION_ACCOUNT_INFO}_REJECTED`: {
            return {...state,
                user: {...state.user,
                    isPending: false,
                    error: action.payload
                }};
        }

        case `${Actions.ACTION_VOTER_ID}_FULFILLED`: {
            if (state.voterId && action.payload === state.voterId.data) {
                return state;
            }

            return {...state,
                voterId: {...state.voterId,
                    isPending: false,
                    data: action.payload,
                    error: undefined
                }};
        }
        case `${Actions.ACTION_VOTER_ID}_PENDING`: {
            return {...state,
                voterId: {...state.voterId,
                    isPending: true
                }};
        }

        case `${Actions.ACTION_VOTER_ID}_REJECTED`: {
            return {...state,
                user: {...state.user,
                    isPending: false,
                    error: action.payload
                }};
        }
    }

    return state;
}

export const AppReducer = (state: AppState = {}, action: AnyAction): AppState => {
    switch (action.type) {
        case Actions.ACTION_SYNC_CONTROLLER: {
            return {...state,
                syncController: action.payload
            }
        }
        case Actions.ACTION_JUKEBOX_CONTROLLER: {
            return {...state,
                jukeboxController: action.payload
            }
        }
    }

    return state;
}