import React from 'react';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { JukeboxClient } from '../JukeboxClient';
import { Typography } from '@mui/material';
import { css } from '@emotion/css';

const menuItemClasses = {
    root: css`
        padding: 4;
    `
};

function renderInputComponent(inputProps) {
    const { classes, inputRef = () => { }, ref, ...other } = inputProps;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputRef: node => {
                    ref(node);
                    inputRef(node);
                },
            }}
            {...other}
        />
    );
}

function renderSuggestion(suggestion: Suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
        <MenuItem selected={isHighlighted} component="div" disableGutters classes={menuItemClasses}>
            <div>
                {parts.map((part, index) =>
                    part.highlight ? (
                        <span key={String(index)} style={{ fontWeight: 500 }}>
                            {part.text}
                        </span>
                    ) : (
                            <strong key={String(index)} style={{ fontWeight: 300 }}>
                                {part.text}
                            </strong>
                        ),
                )}
            </div>
        </MenuItem>
    );
}

function getSuggestionValue(suggestion: Suggestion) {
    return suggestion.label;
}

const suggestionsListStyle = css`
    margin: 0;
    padding: 0;
    list-style-type: none;
`;

interface Suggestion {
    label: string;
    uri: string;
}

export interface Props {
    jukeboxOwner: string;
    jukeboxId: string;
    onSelected: (uri: string) => any;
    classes?: any;
}

export interface State {
    query: string;
    suggestions: Array<Suggestion>;
    searching: boolean;
    selectedSuggestion?: Suggestion;
}

const SEARCH_Q_LIMIT = 3;

class TrackSearchComponentImpl extends React.Component<Props, State> {
    state: State = {
        query: '',
        suggestions: [],
        searching: false
    };

    render() {
        const autosuggestProps = {
            renderInputComponent,
            suggestions: this.state.suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            onSuggestionSelected: this.handleSuggestionSelected,
            getSuggestionValue,
            renderSuggestion,
        };

        return (
            <div>
                <Autosuggest
                    {...autosuggestProps}
                    inputProps={{
                        placeholder: 'Search track by title or artist',
                        value: this.state.query,
                        onChange: this.handleChange('query'),
                    }}
                    theme={{
                        suggestionsList: suggestionsListStyle
                    }}
                    renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                            {options.children}
                        </Paper>
                    )}
                />
                <Typography>{this.getWarningText()}&nbsp;</Typography>
            </div>
        );
    }

    private getWarningText() {
        if (this.state.query.trim().length < SEARCH_Q_LIMIT)
            return "";

        if (this.state.searching) {
            return "Searching ...";
        }

        if (this.state.selectedSuggestion) {
            return "";
        }

        if (this.state.suggestions.length === 0) {
            return "No results found :(";
        }
    }

    private handleSuggestionsFetchRequested = ({ value }) => {
        this.doSearchIfReady(value);
    };

    private handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    private handleSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.setState({
            selectedSuggestion: suggestion,
        });

        this.props.onSelected(suggestion.uri);
    }

    private handleChange = name => (event, { newValue }) => {
        this.setState({
            [name]: newValue,
        } as any);
    };

    private searchTimeout: any;
    private doSearchIfReady(value: string) {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }

        const q = value.trim();
        if (!q || q.length < SEARCH_Q_LIMIT) {
            if (this.state.suggestions.length > 0) {
                this.setState({
                    suggestions: [],
                });
            }
            return;
        }
        this.setState({ searching: true });

        this.searchTimeout = setTimeout(() => {
            JukeboxClient.instance.getSearchResults(this.props.jukeboxId, this.props.jukeboxOwner, q)
                .then(results => {
                    const suggestions: Array<Suggestion> = results.map(res => ({
                        label: `${res.artist} ${res.title}`,
                        uri: res.uri
                    }))

                    this.setState({ suggestions, searching: false });
                });

            this.searchTimeout = undefined;
        }, 300);
    }

}

export const TrackSearchComponent = TrackSearchComponentImpl;
