import { FlexColumn } from './Common';
import styled from '@emotion/styled';

export const Container = styled(FlexColumn)`
    flex-grow: 1;
    text-align: left;
`;

export const ContentContainer = styled("div")`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
`;

export const TrackListContainer = styled("div")`
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
    flex-direction: column;
    padding-left: 1vh;
`;

export const Top3Container = styled("div")`
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
    flex-direction: column;
`;

export const TabsContainer = styled("div")`
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
    margin-top: -24px;
    overflow: hidden;
`;