import * as React from 'react'

import { PlaybackInfo, Tracks, JukeboxInfo, JukeboxStatus } from '../types'

import { PlaylistTracks } from './PlaylistTracks'
import { Container, ContentContainer, TrackListContainer, Top3Container, TabsContainer } from './RightPane.Components';
import { Background, BackgroundOverlay } from './Common';
import { Typography, Icon, Tooltip } from '@mui/material';
import { Tabs, EmptyTabHeaderItem } from './Tabs';
import styled from '@emotion/styled';
import { isPendingRemoval } from '../TrackHelper';

const tracksForTop = 3;
const SCROLL_INTERVAL_MS = 10000;

export interface Props {
    readonly jukebox: JukeboxInfo;
    readonly tracks: Tracks;
    readonly playbackInfo: PlaybackInfo;
    readonly jukeboxId: string;
}

interface State {
    currentTab: number;
    approvedTabCount: number;
    pendingTabCount: number;
    tracksPerTab: number;
    lastTabChangeTime: number;
    lastTracks: Tracks;
    topTracks: Tracks;
    tabTracks: Tracks;
    pendingTracks: Tracks;
}

export class RightPane extends React.PureComponent<Props, State> {

    state: State =  {
        currentTab: 0,
        approvedTabCount: 1,
        pendingTabCount: 0,
        tracksPerTab: undefined,
        lastTabChangeTime: Date.now(),
        lastTracks: null,
        topTracks: [],
        tabTracks: [],
        pendingTracks: []
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State): State {
        if (nextProps.tracks.length) {
            const itemsPerTab = prevState.tracksPerTab || 6;
            const upcomingTrackId = nextProps.jukebox.upcomingTrack && nextProps.jukebox.upcomingTrack.spotifyId;
            let approvedTracks = nextProps.tracks.filter(t => !isPendingRemoval(t) || t.spotifyId === upcomingTrackId);
            if (nextProps.jukebox.upcomingTrack) {
                const upcomingTrackIndex = approvedTracks.findIndex(t => t.syncIndex === nextProps.jukebox.upcomingTrack.syncIndex);

                // move upcoming track to the top
                if (upcomingTrackIndex > 0) {
                    const upcomingTrack = approvedTracks.splice(upcomingTrackIndex, 1);
                    approvedTracks.unshift(...upcomingTrack);
                }
            }

            const topTracks = approvedTracks.slice(0, tracksForTop);
            const tabTracks = approvedTracks.slice(tracksForTop);
            const pendingTracks = nextProps.tracks.filter(isPendingRemoval);

            const approvedTabCount = Math.ceil(tabTracks.length / itemsPerTab);
            const pendingTabCount = Math.ceil((pendingTracks.length) / itemsPerTab);

            return {...prevState,
                tracksPerTab: prevState.tracksPerTab || nextProps.tracks.length,
                topTracks,
                tabTracks,
                pendingTracks,
                approvedTabCount,
                pendingTabCount
            };
        }

        return null;
    }

    componentDidMount() {
        this._activeTabUpdateTimer = setInterval(this.onScrollTimer, 1000);
    }

    componentWillUnmount() {
        clearInterval(this._activeTabUpdateTimer);
    }

    render() {
        const trackInfo = this.props.jukebox.currentTrack ||
        {
            image: undefined,
            artist: 'N/A',
            title: 'N/A'
        };

        const bgStyle = {
            background: !!trackInfo.image ? "url('" + trackInfo.image.url + "')" : undefined
        }


        return (
            <Container>
                <ContentContainer className='right-content'>
                    <Background className='right-background' style={bgStyle}>
                        <BackgroundOverlay className='right-background-overlay' />
                    </Background>
                    <TrackListContainer className='right-tracklist'>
                        <Typography variant="subtitle1" color="primary">
                            Top 3
                        </Typography>
                        <Top3Container>
                            <PlaylistTracks
                                tracks={this.state.topTracks}
                                jukebox={this.props.jukebox}
                                jukeboxId={this.props.jukeboxId}
                                noTrackNumbers={!this.props.jukebox.phoneNumber}
                            />
                        </Top3Container>
                        <Typography variant="subtitle1" color="primary">
                            Coming Up
                        </Typography>
                        <TabsContainer>
                            {this.renderTabs()}
                            <PlaylistTracks
                                tracks={this.getTracksForCurrentTab()}
                                jukebox={this.props.jukebox}
                                jukeboxId={this.props.jukeboxId}
                                noTrackNumbers={!this.props.jukebox.phoneNumber}
                                onRender={this.handleRenderTracks}
                            />
                        </TabsContainer>
                    </TrackListContainer>
                </ContentContainer>
            </Container>
        );
    }

    private getTracksForCurrentTab(): Tracks {
        const { currentTab, tracksPerTab, tabTracks, pendingTracks, approvedTabCount } = this.state;

        const tracksArr = currentTab < approvedTabCount ? tabTracks : pendingTracks;
        const tabIndex = currentTab < approvedTabCount ? currentTab : currentTab - approvedTabCount;
        const tracksStart = tabIndex * tracksPerTab;
        const tracksEnd = tracksStart + tracksPerTab;

        return tracksArr.slice(tracksStart, tracksEnd);
    }

    private handleRenderTracks = (htmlElement: HTMLDivElement) => {
        const { clientHeight, scrollHeight, childElementCount, children } = htmlElement;
        if (childElementCount === 0)
            return;

        if (clientHeight > scrollHeight || !clientHeight)
            return;

        const itemHeight = children.item(0).clientHeight;
        const itemsPerTab = Math.floor(clientHeight / itemHeight);

        if (itemsPerTab === this.state.tracksPerTab)
            return;

        this.setState({ tracksPerTab: itemsPerTab });
    }

    private handleTabChange = (newValue: number) => {
        this.setState((state) => ({...state,
            currentTab: newValue,
            lastTabChangeTime: Date.now()
        }));
    }

    private renderTabs() {
        const { currentTab, approvedTabCount, pendingTabCount } = this.state;

        const tabItems = [];
        for (let i = 0; i < approvedTabCount; i++) {
            tabItems.push(<EmptyTabHeaderItem key={i} onClick={() => this.handleTabChange(i)} />);
        }

        for (let i = 0; i < pendingTabCount; i++) {
            tabItems.push(
                <Tooltip key={i + approvedTabCount} title="Pending tracks" aria-label="Pending tracks">
                    <ApproveButton
                        onClick={() => this.handleTabChange(i + approvedTabCount)}
                    >
                        <Icon color="primary">
                            repeat
                        </Icon>
                    </ApproveButton>
                </Tooltip>
            );
        }

        return (
            <Tabs
                selected={currentTab}
                onChange={this.handleTabChange}
                labels={tabItems}
                color={this.props.jukebox.brandColor}
            />
        );
    }

    private _activeTabUpdateTimer: any;
    private onScrollTimer = () => {
        const running = this.props.jukebox.status === JukeboxStatus.Running;
        if (!running) {
            return;
        }

        if (Date.now() - this.state.lastTabChangeTime < SCROLL_INTERVAL_MS) {
            return;
        }

        this.handleTabChange((this.state.currentTab + 1) % (this.state.approvedTabCount + this.state.pendingTabCount));
    }
}

const ApproveButton = styled("div")`
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
`;