import { EventEmitter } from "events";
import * as Actions from "./actions";
import { PlaybackInfo } from "./types";
import log from "./log";
import { setLastVolume, getLastVolume } from "./LocalStorageManager";

declare global {
    interface Window {
        onSpotifyWebPlaybackSDKReady: Function;
    }
}

export declare namespace Spotify {
    export interface WebPlaybackTrack {
        uri: string; // Spotify URI
        id: string; // Spotify ID from URI (can be null)
        type: string; // Content type: can be "track", "episode" or "ad"
        media_type: string; // Type of file: can be "audio" or "video"
        name: string; // Name of content
        is_playable: boolean; // Flag indicating whether it can be played
        album: {
            uri: string; // Spotify Album URI
            name: string;
            images: Array<{
                url: string;
            }>;
        };
        artists: Array<{ uri: string; name: string }>;
    }

    export interface WebPlaybackState {
        context: {
            uri: string; // The URI of the context (can be null)
            metadata: any; // Additional metadata for the context (can be null)
        };
        disallows: {
            // A simplified set of restriction controls for
            pausing: boolean; // The current track. By default, these fields
            peeking_next: boolean; // will either be set to false or undefined, which
            peeking_prev: boolean; // indicates that the particular operation is
            resuming: boolean; // allowed. When the field is set to `true`, this
            seeking: boolean; // means that the operation is not permitted. For
            skipping_next: boolean; // example, `skipping_next`, `skipping_prev` and
            skipping_prev: boolean; // `seeking` will be set to `true` when playing an
            // ad track.
        };
        duration: number;
        paused: boolean; // Whether the current track is paused.
        position: number; // The position_ms of the current track.
        repeat_mode: number; // The repeat mode. No repeat mode is 0,
        // once-repeat is 1 and full repeat is 2.
        shuffle: boolean; // True if shuffled, false otherwise.
        track_window: {
            current_track: WebPlaybackTrack; // The track currently on local playback
            previous_tracks: Array<WebPlaybackTrack>; // Previously played tracks. Number can vary.
            next_tracks: Array<WebPlaybackTrack>; // Tracks queued next. Number can vary.
        };
    }

    class Player extends EventEmitter {
        constructor(options: any);
        _options: any;
        connect(): Promise<boolean>;
        disconnect(): void;
        getCurrentState(): Promise<WebPlaybackState>;
        resume(): Promise<void>;
        pause(): Promise<void>;
        getVolume(): Promise<number>;
        setVolume(volume: number): Promise<number>;
    }
}

let _sdkReady = false;
let _sdkReadyCallback: Function;
window.onSpotifyWebPlaybackSDKReady = () => {
    _sdkReady = true;
    log.debug("player sdk is ready");
    if (_sdkReadyCallback) {
        _sdkReadyCallback();
    }
};

export class SpotifyPlayer extends EventEmitter {
    private _player: Spotify.Player;
    private _getToken: () => Promise<string>;
    private _deviceId: string;

    public static create(getTokenCb: () => Promise<string>): Promise<SpotifyPlayer> {
        const instance = new SpotifyPlayer();
        return instance.init(getTokenCb);
    }

    private init(getTokenCb: () => Promise<string>): Promise<SpotifyPlayer> {
        this._getToken = getTokenCb;
        const sdkPromise: Promise<any> = _sdkReady
            ? Promise.resolve()
            : new Promise((resolve) => {
                  log.debug("Waiting for sdk ready");
                  _sdkReadyCallback = resolve;
              });

        return sdkPromise.then(() => this.initSdk()).then(() => this);
    }

    public reset(): Promise<SpotifyPlayer> {
        log.debug("Player reset");
        this.disconnect();
        return this.init(this._getToken).catch((error) => {
            log.error("Failed to reset player", error);
            throw error;
        });
    }

    public get deviceId() {
        return this._deviceId;
    }

    private initSdk(): Promise<any> {
        const player = new Spotify.Player({
            name: "Jukebob",
            getOAuthToken: (cb) => {
                log.debug("spotify requested new token");
                this._getToken()
                    .then((token) => {
                        log.debug("providing access token to player", token);
                        cb(token);
                    })
                    .catch((e) => {
                        log.error("Unable to get new token", e);
                    });
            },
            volume: getLastVolume() || 1
        });

        this._player = player;

        // Error handling
        this.addPlayerListener("initialization_error", (error) =>
            this.handlePlayerError("initialization_error", error)
        );
        this.addPlayerListener("authentication_error", (error) =>
            this.handlePlayerError("authentication_error", error)
        );
        this.addPlayerListener("account_error", (error) => this.handlePlayerError("account_error", error));
        this.addPlayerListener("playback_error", (error) => this.handlePlayerError("playback_error", error));

        // Playback status updates
        this.addPlayerListener("player_state_changed", this.handlePlayerStateChange);

        const readyPromise = new Promise<void>((resolve, reject) => {
            // Ready
            this.addPlayerListener("ready", ({ device_id }) => {
                this._deviceId = device_id;
                Actions.setDeviceInfo({
                    id: device_id,
                    is_active: true,
                    is_restricted: false,
                    name: "Player SDK",
                    type: "Computer"
                });

                log.debug("Ready with Device ID", device_id);
                resolve();
            });

            // Not Ready
            this.addPlayerListener("not_ready", ({ device_id }) => {
                log.error("Device has gone offline, ID", device_id);

                reject();
            });
        });

        // Connect to the player!
        return player.connect().then((success) => {
            log.debug("The Web Playback SDK connect:", success);
            if (!success) {
                log.error("Unable to connect to Web playback SDK");
            }

            return readyPromise;
        });
    }

    private _playerListeners = [];
    private addPlayerListener(event: string, listener: (...args: any[]) => void) {
        this._player.addListener(event, listener);
        this._playerListeners.push({
            event,
            listener
        });
    }

    private removeAllPlayerListers() {
        this._playerListeners.forEach((l) => {
            this._player.removeListener(l.event, l.listener);
        });
        this._playerListeners = [];
    }

    private handlePlayerError = (error: string, errorObj: any) => {
        this.emit("error", errorObj.message, error);
    };

    private handlePlayerStateChange = (state: Partial<Spotify.WebPlaybackState>) => {
        log.debug("Player state change", state);
        if (state === null) {
            log.debug("Player state is null !");
        }

        if (!state) {
            state = {
                paused: true,
                repeat_mode: 0,
                shuffle: false,
                position: 0,
                track_window: {
                    current_track: undefined,
                    next_tracks: undefined,
                    previous_tracks: undefined
                },
                duration: 0
            };
        }

        const item = state.track_window.current_track;
        const playbackInfo = {
            playing: !state.paused,
            repeat: state.repeat_mode > 0,
            shuffle: state.shuffle,
            progressMs: state.position,
            track: {
                syncIndex: undefined,
                spotifyId: !!item && item.uri,
                lastVotesUp: 0,
                lastVotesDown: 0,
                artist: !!item && item.artists[0].name,
                title: !!item && item.name,
                lastPlayedTimestamp: 0,
                lastVoteTimestamp: 0,
                image: !!item && !!item.album.images && item.album.images.length > 0 ? item.album.images[0] : undefined,
                voters: undefined
            },
            durationMs: !!item && state.duration
        } as PlaybackInfo;

        Actions.setPlaybackInfo(playbackInfo);
        this.emit("playbackInfoChanged", playbackInfo);
    };

    public getCurrentState() {
        return this._player.getCurrentState();
    }

    public pause() {
        return this._player.pause();
    }

    public resume() {
        return this._player.resume();
    }

    public getVolume(): Promise<number> {
        return this._player.getVolume();
    }

    public setVolume(volume: number): Promise<number> {
        setLastVolume(volume);

        return this._player.setVolume(volume);
    }

    public disconnect() {
        log.debug("SpotifyPlayer disconnect");
        this.removeAllPlayerListers();
        return this._player.disconnect();
    }
}
