import * as React from 'react'
import { withRouter } from 'react-router'

import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Typography from '@mui/material/Typography';

import { AsyncJukeboxesState } from '../types'
import { JukeboxClient } from '../JukeboxClient'

import { Container, ListContainer } from '../components/Common'
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';

import { Fab } from '@mui/material';
import { ConfirmDialog } from '../components/ConfirmDialog';


interface Props {
    readonly history?: any;
}

interface State {
    jukeboxes: AsyncJukeboxesState;
    deleteConfirmPending?: boolean;
    jukeboxIdToDelete?: string;
}

class MyJukeboxesView extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = { jukeboxes: { isPending: false }};
    }

    UNSAFE_componentWillMount() {
        this.loadData();
    }

    private loadData() {
        this.setState((state) => ({...state, jukeboxes: {...state.jukeboxes, isPending: true }}));

        return JukeboxClient.instance.getJukeboxes()
            .then(data => {
                this.setState((state) =>({...state, jukeboxes: {
                    isPending: false,
                    data: data
                }}));
            })
            .catch(err => {
                this.setState((state) => ({...state, jukeboxes: {
                    isPending: false,
                    error: err
                }}))
            });
    }

    render() {
        const { jukeboxes, deleteConfirmPending } = this.state;

        if (jukeboxes.error) {
            const errorText = typeof(this.state.jukeboxes.error) == 'object' ? this.state.jukeboxes.error.message : this.state.jukeboxes.error;
            return (
                <Container>
                    <p>An error occurred: {errorText}</p>
                </Container>
            );
        }

        if (jukeboxes.isPending) {
            return (
                <Container>
                    <CircularProgress />
                </Container>
            );
        }

        const jbs = this.state.jukeboxes.data.map(v => {
            return (
                <ListItem
                    key={v._id}
                    button
                    onClick={() => this.props.history.push(`/${v._id}/admin`)}
                >
                    <ListItemText>
                        {v.friendlyName}
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <IconButton
                            onClick={() => this.props.history.push(`/${v._id}`)}
                            color="primary"
                        >
                            <Icon>playlist_play</Icon>
                        </IconButton>
                        <IconButton
                            onClick={() => this.props.history.push(`/${v._id}/admin`)}
                            color="primary"
                        >
                            <Icon>create</Icon>
                        </IconButton>
                        <IconButton
                            onClick={() => this.handleDeleteClick(v._id)}
                            color="primary"
                        >
                            <Icon>delete</Icon>
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            )
        })

        return (
            <Container>
                <Typography variant="h3" gutterBottom>
                    My Jukeboxes
                </Typography>
                <ListContainer>
                    {jbs.length ? (
                        <List style={{ flex: 1}}>
                            {jbs}
                        </List>
                    ) : (
                        <Typography gutterBottom>You do not have any jukeboxes yet.</Typography>
                    )}
                    <Container>
                        <Fab
                            variant="extended"
                            onClick={() => this.props.history.push(`/setup`)}
                            color="primary"
                        >
                            <Icon>add_circle</Icon>
                            Create jukebox
                        </Fab>
                    </Container>
                </ListContainer>
                {deleteConfirmPending && (
                    <ConfirmDialog
                        onCancel={this.handleCancelDelete}
                        onConfirm={this.handleConfirmDelete}
                    >
                        Are you sure you want to delete the jukebox?
                    </ConfirmDialog>
                )}
            </Container>
        );
    }

    private handleDeleteClick = (jukeboxIdToDelete: string) => {
        this.setState({ deleteConfirmPending: true, jukeboxIdToDelete });
    }

    private handleConfirmDelete = () => {
        const { jukeboxIdToDelete } = this.state;

        this.setState({ deleteConfirmPending: false });
        return JukeboxClient.instance.deleteJukebox(jukeboxIdToDelete)
            .then(() => this.loadData());
    }

    private handleCancelDelete = () => {
        this.setState({ deleteConfirmPending: false });
    }
}

export default withRouter(MyJukeboxesView);