// localStorage token logic deprecated

export interface AppTokens {
    readonly spotifyAccessCode?: string;
    readonly spotifyRefreshToken?: string;
}

const defaultTokens: AppTokens = {};

const KEY = "tokens";
export function setTokens(tokens: AppTokens) {
    window.localStorage.setItem(KEY, JSON.stringify(tokens));
}

export interface JukeboxInfo {
    readonly id: string;
}

export function getTokens(): AppTokens {
    const storedTokens = window.localStorage.getItem(KEY);
    if (!storedTokens)
        return defaultTokens;

    const storedObj = JSON.parse(storedTokens);
    if (storedObj.spotifyAccessTokenExpireDate)
        storedObj.spotifyAccessTokenExpireDate = new Date(storedObj.spotifyAccessTokenExpireDate);

    if (storedObj.spotifyRefreshTokenExpireDate)
        storedObj.spotifyRefreshTokenExpireDate = new Date(storedObj.spotifyRefreshTokenExpireDate);

    if (storedObj.syncTokenExpireDate)
        storedObj.syncTokenExpireDate = new Date(storedObj.syncTokenExpireDate);

    return storedObj;
}

const KEY_JUKEBOX_INFO = "jukeboxInfo";
export function setJukeboxIdAndSid(jukeboxId: string) {
    const info: JukeboxInfo = {
        id: jukeboxId
    }

    window.localStorage.setItem(KEY_JUKEBOX_INFO, JSON.stringify(info));
}

export function getJukeboxInfo(): JukeboxInfo {
    return JSON.parse(window.localStorage.getItem(KEY_JUKEBOX_INFO));
}

const KEY_VOLUME = "playerVolume";
export function getLastVolume() {
    const vol = window.localStorage.getItem(KEY_VOLUME);
    if (vol !== null) {
        return parseFloat(vol);
    }

    return 1;
}

export function setLastVolume(volume: number) {
    window.localStorage.setItem(KEY_VOLUME, volume + "");
}