import * as React from 'react'
import { connect } from 'react-redux';

import { Loading, Error } from './Components';
import { StepComponentProps, SaveJukeboxStepData } from './types';
import { StoreType, AccountState } from '../../types';
import { FlexColumn } from '../../components/Common';
import { TextField } from '@mui/material';
import styled from '@emotion/styled';

interface State {
    lastError?: any;
    isSaving?: boolean;
    data: {
        name: string;
        brandColor: string;
        logoUrl: string;
    }
}

interface Props extends StepComponentProps<SaveJukeboxStepData> {
    account: AccountState;
}

class StepSaveJukeboxImpl extends React.PureComponent<Props, State> {

    state: State = {
        data: this.getInitialStateFromProps()
    };

    UNSAFE_componentWillMount() {
        this.props.onNext(() => this.saveJukebox());
        this.props.onData(this.state.data);
    }

    private getInitialStateFromProps() {
        const { jukebox, playlist } = this.props.stepDatas;

        return {
            name: (jukebox && jukebox.name) || playlist.playlistName || "",
            brandColor: (jukebox && (jukebox.brandColor)) || "",
            logoUrl: (jukebox && (jukebox.logoUrl)) || ""
        }
    }

    render() {
        const { lastError, isSaving, data } = this.state;
        if (lastError) {
            const errorText = typeof(lastError) == 'object' ? lastError.message : lastError;
            return <Error>{errorText}</Error>;
        }

        if (isSaving) {
            return <Loading>Saving jukebox...</Loading>;
        }

        return (
            <Container className="details">
                <TextField
                    label="Jukebox name *"
                    error={!data.name}
                    style={{ margin: 8 }}
                    // placeholder=""
                    // helperText="* required"
                    fullWidth
                    value={data.name}
                    onChange={this.handleChange('name')}
                    // margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="Main color"
                    error={!this.isValidColor(data.brandColor)}
                    style={{ margin: 8 }}
                    // placeholder=""
                    // helperText="* required"
                    fullWidth
                    value={data.brandColor}
                    onChange={this.handleChange('brandColor')}
                    // margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    label="Logo image URL"
                    error={false}
                    style={{ margin: 8 }}
                    // placeholder=""
                    // helperText="* required"
                    fullWidth
                    value={data.logoUrl}
                    onChange={this.handleChange('logoUrl')}
                    // margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Container>
        );
    }

    private handleChange = (name: string) => event => {
        const data = {...this.state.data,
            [name]: event.target.value
        }

        this.setState({ data }, () => {
            this.propagateData();
        });
    };

    private isValidColor(strColor: string) {
        if (!strColor)
           return true;
        const s = new Option().style;
        s.color = strColor;
        return s.color === strColor;
    }

    private propagateData() {
        const { data } = this.state;
        if (!data.name) {
            return this.props.onClear();
        }

        return this.props.onData(data);
    }

    private saveJukebox(): Promise<any> {
        this.setState({ isSaving: true });
        return this.props.onSave()
            .then(() => {
                this.setState({ isSaving: false });
            })
            .catch(err => {
                this.setState({ lastError: err, isSaving: false })
                throw err;
            });
    }
}

const mapStateToProps = (state: StoreType) => {
    return {
        account: state.account
    }
}

export const StepSaveJukebox = connect(mapStateToProps)(StepSaveJukeboxImpl);


const Container = styled(FlexColumn)`
    padding: 2vh;
    max-width: 300px;

    margin-left: auto;
    margin-right: auto;
`;