import * as React from "react";
import { Tracks, SyncEvent, SyncEventType } from '../types';
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { withSnackbar, WithSnackbarProps, VariantType } from "notistack";
import { SyncController } from "../SyncController";
import log from "../log";
import { voteGreenColor, voteRedColor } from "../theme";

const EventsForMinimal = new Set([SyncEventType.NewTrack, SyncEventType.NextTrack]);

export interface Props extends WithSnackbarProps {
    minimal?: boolean;
    tracks: Tracks;
    syncController: SyncController;
}
class NotificationBarImpl extends React.Component<Props> {

    render() {
        return null;
    }

    componentDidMount() {
        this.props.syncController.addListener("syncEvent", this.handleSyncEvent);
        this.props.syncController.addListener("tokenExpired", this.handleSyncDisconnected);
        this.props.syncController.addListener("disconnected", this.handleSyncDisconnected);
        this.props.syncController.addListener("connected", this.handleSyncConnected);
    }

    componentWillUnmount() {
        this.props.syncController.removeListener("syncEvent", this.handleSyncEvent);
        this.props.syncController.removeListener("tokenExpired", this.handleSyncDisconnected);
        this.props.syncController.removeListener("disconnected", this.handleSyncDisconnected);
        this.props.syncController.removeListener("connected", this.handleSyncConnected);
    }

    private handleSyncEvent = (event: SyncEvent) => {
        const { minimal } = this.props;
        if (minimal && !EventsForMinimal.has(event.type)) {
            return;
        }

        const variant: VariantType = "default";
        this.props.enqueueSnackbar(this.getNotificationContent(event), {
            variant,
            key: event.sid,
            autoHideDuration: 4000
        });
    }

    private handleSyncDisconnected = () => {
        const content = "Connection lost";

        this.props.enqueueSnackbar(content, {
            variant: "warning",
            key: "syncDisconnected",
            preventDuplicate: true,
            persist: true
        });
    }

    private handleSyncConnected = () => {
        this.props.closeSnackbar("syncDisconnected");
    }

    private getNotificationContent(event: SyncEvent) {
        const track = event.trackSyncIndex !== undefined ? this.props.tracks.find(t => t.syncIndex === event.trackSyncIndex) : undefined;
        const trackArtist = track ? track.artist : "";
        const trackTitle = track ? track.title : "";

        let pref = "";
        switch (event.type) {
            case SyncEventType.NewTrack:
                pref = "ADDED";
                break;
            case SyncEventType.NextTrack:
                pref = "COMING UP";
                break;
            case SyncEventType.RemovedTrack:
                pref = "REMOVED";
                break;
            case SyncEventType.VoteDown:
                pref = "DOWN";
                break;
            case SyncEventType.VoteUp:
                pref = "UP";
                break;
        };

        if (!track || !pref) {
            log.warn("Unknown event", track, pref);
            return null;
        }

        return (
            <Typography component="div" variant="h5" className="event-info">
                <EventLabelSpan type={event.type}>{pref}</EventLabelSpan>
                <Typography variant="h5" component="span" color="textSecondary"> </Typography>
                <Typography component="span" style={{ fontWeight: "bold" }} variant="h5" color="textPrimary">{trackTitle}</Typography>
                <Typography variant="h5" component="span" color="textSecondary"> | {trackArtist}</Typography>
            </Typography>
        );
    }
}

export const NotificationBar = withSnackbar(NotificationBarImpl);

const EventLabelSpan = styled("span")<{type: SyncEventType}>`
    display: inline-block;
    font-weight: bold;

    color: ${props => {
        switch (props.type) {
            case SyncEventType.NewTrack:
            case SyncEventType.VoteUp:
                return voteGreenColor;
            case SyncEventType.RemovedTrack:
            case SyncEventType.VoteDown:
                return voteRedColor;
            default:
                return "#e69011";
        }
    }};
`;