import * as React from 'react';
import { Provider } from 'react-redux';
import { withRouter } from 'react-router';
import ReactGA from 'react-ga';


import JukeboxPage from './views/JukeboxView';

import {SetupView } from './views/SetupView';
import { HomeView } from './views/HomeView';
import { MyAccountView } from './views/MyAccountView';
import MyJukeboxesView from './views/MyJukeboxesView';
import JukeboxAdminView from './views/JukeboxAdminView';

import NavBar from './components/NavBar';

import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import styled from '@emotion/styled';
import { SnackbarProvider } from 'notistack';

import { store } from './store';
import { snackBarRootTheme, theme } from './theme';

const RootContainer = styled('div')`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;

    font-family: 'Open Sans', sans-serif;
    background: #000;
    z-index: 0;
`;


const snackbarTheme = {
    root: snackBarRootTheme
}

const RootComponentImpl = () => {
    const isMinimal = window.location.href.endsWith('/m');
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    maxSnack={isMinimal ? 1 : 3} classes={snackbarTheme} >
                    <RootContainer className='root'>
                        {!isMinimal && <NavBar />}
                        <Switch>
                            <Route path='/account' component={MyAccountView} />
                            <Route path='/jukeboxes' component={MyJukeboxesView} />
                            <Route path='/setup' exact component={SetupView} />
                            <Route path='/setup/:jukeboxId' component={SetupView} />
                            <Route path='/:jukeboxId/admin' component={JukeboxAdminView} />
                            <Route path='/:jukeboxId/m' render={(props) => <JukeboxPage {...props} minimal />} />
                            <Route path='/:jukeboxId' exact component={(props) => <JukeboxPage {...props} />} />

                            <Route component={HomeView} />
                        </Switch>
                        {/* For Google analytics */}
                        <Route path="/" render={({location}) => {
                            ReactGA.pageview(location.pathname + location.search);
                            return null;
                        }} />
                    </RootContainer>
                </SnackbarProvider>
            </ThemeProvider>
        </Provider>);
};
export const RootComponent = withRouter(RootComponentImpl);
