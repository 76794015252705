import * as React from 'react'
import * as ReactDOM from 'react-dom'

import { Track, Tracks, JukeboxInfo } from '../types'

import { PlaylistTrack } from './PlaylistTrack';
import {
    StyledScrollingFlipMove,
} from './PlaylistTracks.Components';
import { DebouncedRender } from './DebouncedRender';

const FLIP_MOVE_DURATION = 300;

interface Props {
    tracks: Tracks;
    jukebox: JukeboxInfo;
    selectable?: boolean;
    onSelectionChange?: () => void;
    showVoteButtons?: boolean;
    disableVoteUpButtons?: boolean;
    disableVoteDownButtons?: boolean;
    jukeboxId: string;
    minimal?: boolean;
    noTrackNumbers?: boolean;
    onRender?: (htmlElement: HTMLDivElement) => void;
}

interface State {
    selected: Set<number>;
    prevTrackProps: Props;
}

export class PlaylistTracks extends React.Component<Props, State> {

    private rootRef: HTMLDivElement;

    public static defaultProps: Partial<Props> = {
        minimal: false,
        selectable: false,
        noTrackNumbers: false
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            selected: new Set<number>(),
            prevTrackProps: props,
        }
    }

    componentDidUpdate() {
        const { onRender } = this.props;
        if (onRender && this.rootRef) {
            onRender(this.rootRef);
        }
    }

    render() {
        const { jukeboxId, minimal, noTrackNumbers,showVoteButtons, selectable, tracks, disableVoteUpButtons, disableVoteDownButtons } = this.props;
        const { upcomingTrack, currentTrack } = this.props.jukebox;
        const { selected, } = this.state;

        const lastVotes = [currentTrack && currentTrack.spotifyId, upcomingTrack && upcomingTrack.spotifyId];

        // console.log("rendering tracks", tracks.map(t => t.syncIndex));

        const trackItems = tracks
            .map((t, i) => {
                const showLastVotes = lastVotes.indexOf(t.spotifyId) >= 0;
                return (
                    <PlaylistTrack
                        track={t}
                        key={t.syncIndex}
                        selected={selected.has(t.syncIndex)}
                        onSelectionChange={selectable && this.onToggleSelection}
                        showVoteButtons={showVoteButtons}
                        showVotes={!showLastVotes}
                        showLastVotes={showLastVotes}
                        jukeboxId={jukeboxId}
                        minimal={minimal}
                        noTrackNumbers={noTrackNumbers}
                        disableVoteUpButtons={disableVoteUpButtons}
                        disableVoteDownButtons={disableVoteDownButtons}
                    />
                );
            });

        return (
            <DebouncedRender interval={FLIP_MOVE_DURATION} innerProps={this.props}>
                {() => (
                    <StyledScrollingFlipMove className='track-list-scrollable'
                        ref={this.setRootElementRef}
                        typeName="ul"
                        hidescroll={minimal ? "true" : undefined}>
                        {trackItems}
                    </StyledScrollingFlipMove>
                )}
            </DebouncedRender>
        )
    }

    private onToggleSelection = (track: Track) => {
        const trackNumber = track.syncIndex;

        if (this.state.selected.has(trackNumber)) {
            this.state.selected.delete(trackNumber);
        }
        else {
            this.state.selected.add(trackNumber);
        }

        if (this.props.onSelectionChange) {
            this.props.onSelectionChange();
        }

        this.forceUpdate();
    }

    private setRootElementRef = (element) => {
        if (element) {
            element = ReactDOM.findDOMNode(element);
        }
        this.rootRef = element;
    }
}