import * as React from "react";
import { Container, FlexColumn } from "../../components/Common";
import { Typography, CircularProgress } from "@mui/material";
import styled from "@emotion/styled/base";

export const Error: React.FunctionComponent<{ children: React.ReactNode }> = (props) => (
    <div>
        <p>An error occurred: {props.children}</p>
    </div>
);

export const Loading: React.FunctionComponent<{ children: React.ReactNode }> = (props: { children: React.ReactNode[] }) => (
    <LoadingContainer className='loading-container'>
        <FlexColumn>
            <Typography gutterBottom>{props.children}</Typography>
            <CircularProgress />
        </FlexColumn>
    </LoadingContainer>
);

const LoadingContainer = styled(Container)`
    & > * {
        margin-top: auto;
        margin-bottom: auto;
    }
`;

const AutoGrowFlexColumn =  styled(FlexColumn)`
    align-self: stretch;
    flex: 1 1 auto;
    overflow: auto;
    text-align: center;
`;

export const StepContentContainer = styled(AutoGrowFlexColumn)`

`;

export const StepContainer = styled(AutoGrowFlexColumn)`
    margin-top: 2vh;
`;


export const StepComponentContainer = styled(AutoGrowFlexColumn)`
    margin-top: 2vh;
`;