import * as React from "react";
import styled from "@emotion/styled";
import { QRCode } from "react-qrcode-logo";

import { asYouType } from "libphonenumber-js";
import { Typography } from "@mui/material";
import { JukeboxInfo } from "../types";
import { FlexColumn } from "./Common";
import { greyColor, voteGreenColor, voteRedColor } from "../theme";

import LocalImage from "../twilio-mark-red.svg";

export interface Props {
    jukebox: JukeboxInfo;
}

export class VotingDetails extends React.PureComponent<Props> {
    render() {
        const { jukebox } = this.props;

        const qrComponent = (
            <QRCodeContainer className="qr-container">
                <a href={jukebox.shortUrl} target="blank">
                    <QRCode value={jukebox.shortUrl} logoImage={LocalImage} size={150} logoWidth={30} />
                </a>
                <a href={jukebox.shortUrl} target="blank">
                    {jukebox.shortUrl}
                </a>
            </QRCodeContainer>
        );
        const voteOptions = [
            {
                title: (
                    <>
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                            QR Code
                        </Typography>
                    </>
                ),
                content: qrComponent
            }
        ];

        if (jukebox.phoneNumber) {
            voteOptions.unshift({
                title: (
                    <>
                        <Typography variant="subtitle1" color="primary">
                            Text
                        </Typography>
                        <Typography variant="h5" color="textPrimary" gutterBottom>
                            {new asYouType().input(jukebox.phoneNumber)}
                        </Typography>
                    </>
                ),
                content: <TextVoteOption />
            });
        }

        return (
            <VotingDetailsContainer className="voting-details">
                <Typography variant="h1" color="primary" gutterBottom>
                    Vote
                </Typography>
                {voteOptions.map((option, index) => {
                    return (
                        <FlexColumn key={index} className="vote-option">
                            {option.title}
                            {option.content}
                            {index === 0 && <Typography>&nbsp;</Typography>}
                        </FlexColumn>
                    );
                })}
            </VotingDetailsContainer>
        );
    }
}

const VotingDetailsContainer = styled(FlexColumn)``;

const QRCodeContainer = styled("div")`
    display: flex;
    flex-direction: column;

    a,
    a:active,
    a:visited {
        color: ${greyColor};
        text-decoration: none;
        word-break: break-all;
    }
`;

const voteSamples = [
    <>
        <Typography variant="h6" color="textSecondary">
            e.g.{" "}
        </Typography>
        <Typography variant="h6" style={{ color: voteGreenColor }}>
            <strong>UP</strong>
        </Typography>
        <Typography variant="h6" color="textPrimary">
            {" "}
            16
        </Typography>
    </>,
    <>
        <Typography variant="h6" color="textSecondary">
            e.g.{" "}
        </Typography>
        <Typography variant="h6" style={{ color: voteRedColor }}>
            <strong>DOWN</strong>
        </Typography>
        <Typography variant="h6" color="textPrimary">
            {" "}
            18
        </Typography>
    </>,
    <>
        <Typography variant="h6" color="textSecondary">
            e.g.{" "}
        </Typography>
        <Typography variant="h6" style={{ color: voteGreenColor }}>
            <strong>ADD</strong>
        </Typography>
        <Typography variant="h6" color="textPrimary">
            {" "}
            metallica fuel
        </Typography>
    </>
];

class TextVoteOption extends React.PureComponent<{}, { sampleIndex: number }> {
    private _updateInterval;
    state = {
        sampleIndex: 0
    };

    componentDidMount() {
        this._updateInterval = setInterval(() => {
            this.setState((currentState) => ({
                ...currentState,
                sampleIndex: (currentState.sampleIndex + 1) % voteSamples.length
            }));
        }, 10000);
    }

    componentWillUnmount() {
        clearInterval(this._updateInterval);
    }

    render() {
        const VoteTitles = (
            <Typography>
                <Typography variant="h5" style={{ color: voteGreenColor }}>
                    <strong>UP</strong>
                </Typography>
                <Typography variant="h5">
                    {" "}
                    |{" "}
                </Typography>
                <Typography variant="h5" style={{ color: voteRedColor }}>
                    <strong>DOWN</strong>
                </Typography>
                <Typography variant="h5">
                    {" "}
                    |{" "}
                </Typography>
                <Typography variant="h5" style={{ color: voteGreenColor }}>
                    <strong>ADD</strong>
                </Typography>
            </Typography>
        );

        return (
            <FlexColumn className="text-vote-container">
                {VoteTitles}
                <Typography variant="h6" color="textSecondary">
                    plus{" "}
                    <Typography variant="h6" color="textPrimary">
                        track number / artist / title
                    </Typography>
                </Typography>
                <Typography variant="h6" color="textPrimary">
                    <SamplesContainer>
                        {voteSamples.map((s, i) => (
                            <SampleContainer key={i} active={this.state.sampleIndex === i}>
                                {s}
                            </SampleContainer>
                        ))}
                        {voteSamples[this.state.sampleIndex]}
                    </SamplesContainer>
                </Typography>
            </FlexColumn>
        );
    }
}

const SamplesContainer = styled("div")`
    position: relative;
`;

const SampleContainer = styled("div")<{ active: boolean }>`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: opacity ${(props) => (props.active ? ".5" : ".15")}s ease-in-out;
`;
