import * as React from 'react';
import styled from "@emotion/styled";
import FlipMove from 'react-flip-move';

export const Container = styled("div")`
    align-items: stretch;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    text-align: center;
`;

interface StyledFlipMoveProps extends FlipMove.FlipMoveProps {
    hidescroll?: string;
    children?: React.ReactNode;
}

export const StyledFlipMove = styled(FlipMove)`
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow-y: ${(props: StyledFlipMoveProps) => props.hidescroll ? "hidden" : "hidden"};
`;

export const StyledStaticFlipMove = styled(StyledFlipMove)`
    flex: 1 0 auto;
    background-image: linear-gradient(to bottom, rgba(24,24,24,1) 0%, rgba(255,255,255,0) 100%);
`;

export const StyledScrollingFlipMove = styled(StyledFlipMove)`
    flex: 1 1 auto;
`;