import { AxiosInstance } from 'axios';
import axiosRetry, { IAxiosRetryConfig, exponentialDelay } from 'axios-retry';

export function applyAxiosRetry(axios: AxiosInstance) {
    const retryConfig: IAxiosRetryConfig = {
        retries: 8,
        retryDelay: exponentialDelay
    };

    axiosRetry(axios, retryConfig);
}