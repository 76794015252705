import { JukeboxStatus, Track, SyncEventType, SyncEvent } from './types'
import { JukeboxController } from './JukeboxController'
import log from "./log";
import { getUserVoteCount, getMaxVotesPerUser } from './JukeboxHelper';

const StringSimilarity = require('string-similarity');

const SCORE_THRESHOLD_FOR_VOTE = 1.0;
const SCORE_THRESHOLD_FOR_ADD = 1.3;

export class CommandProcessor {

    private _controller: JukeboxController;
    private _initialized: boolean = false;

    public async initController(jukeboxController: JukeboxController) {
        this._controller = jukeboxController;
        this._controller.syncController.onCommand = this.onSyncCommand;
        await jukeboxController.syncController.initController();
        this._initialized = true;
    }

    public stop() {
        if (this._controller) {
            this._controller.syncController.onCommand = null;
            this._controller = null;
        }
    }

    private KNOWN_PUBLIC_COMMANDS = ['up', 'down', 'add', 'vote'];
    private KNOWN_POWER_COMMANDS = ['next', 'start', 'pause', 'veto'];

    private onSyncCommand = (command: string, from: string) => {
        if (this._controller.jukebox.status === JukeboxStatus.NotReady) {
            return log.warn("Not initialized, dropping command: ", command);
        }

        function isNumeric(n: any) {
            return !isNaN(parseInt(n)) && isFinite(n);
        }

        if (typeof(command) !== 'string')
            command = '';

        const isPOWERUSER = from === 'website' || from === '+37256955080' || from === this._controller.jukebox.owner;

        const parts: string[] = command.split(' ');
        // vote up command, one numeric param only
        if (parts.length === 1 && isNumeric(parts[0])) {
            this.handleVoteUpCommand(from, parseInt(parts[0]))
        }
        else {
            let trackIndexToVote = -1;
            let cmd = parts[0].toLowerCase();

            const isKnownCommand = !!this.KNOWN_PUBLIC_COMMANDS.find(v => v === cmd) ||
              (isPOWERUSER && !!this.KNOWN_POWER_COMMANDS.find(v => v === cmd));

            if (!isKnownCommand)
                return;

            if (parts.length > 1) {
                // take whole string or string after the command as search
                const param = parts.slice(isKnownCommand ? 1 : 0).join(' ');
                if (isNumeric(param)) {
                    trackIndexToVote = parseInt(param);
                }
                else {
                    const scoreThreshold = cmd === 'add' ? SCORE_THRESHOLD_FOR_ADD : SCORE_THRESHOLD_FOR_VOTE;

                    const bestMatchIndex = this.findExistingTrackIndexByString(param, scoreThreshold);
                    if (bestMatchIndex >= 0) {
                        trackIndexToVote = bestMatchIndex + 1;
                    }
                    else {
                        log.debug('not good enough match found from playlist');
                    }
                }

                if (trackIndexToVote >= 1) {
                    if (cmd === 'up' || cmd === 'add') {
                        this.handleVoteUpCommand(from, trackIndexToVote)
                    }
                    else if (cmd === 'down') {
                        this.handleVoteDownCommand(from, trackIndexToVote)
                    }
                    else if (cmd === 'veto') {
                        this.handleVoteDownCommand(from, trackIndexToVote, 100);
                    }
                }
                else {
                    if (cmd === 'add') {
                        this.handleAddTrackToJukeboxCommand(from, param);
                    }
                }
            }
            else if (cmd === 'start') {
                this._controller.start();
            }
            else if (cmd === 'pause') {
                this._controller.stop();
            }
            else if (cmd === 'next') {
                this._controller.setupNextTrackAndPlay();
            }
        }
    };

    private findExistingTrackIndexByString(str: string, scoreThreshold: number): number {
        let maxScore = -1;
        let bestMatch = -1;

        this._controller.tracks.forEach((track) => {
            const score = this.getSearchScoreForTrack(str, track);

            if (score > maxScore) {
                bestMatch = track.jukeboxIndex;
                maxScore = score;
            }
        });

        log.debug('best match for:', str, 'is track:', bestMatch, 'with score', maxScore);
        if (maxScore < scoreThreshold)
            return -1;

        return bestMatch;
    }

    private getSearchScoreForTrack(search: string, track: Track) {
        if (search.trim() === track.spotifyId)
            return 100;

        return this.getSearchScore(search, track.artist, track.title);
    }

    private getSearchScore(search: string, artist: string, title: string) {
        const scoreArtist = StringSimilarity.compareTwoStrings(search, artist);
        const scoreTitle = StringSimilarity.compareTwoStrings(search, title);

        return scoreArtist + scoreTitle;
    }

    private getTrackByIndex(trackIndex: number) {
        trackIndex--;
        const track = this._controller.tracks.find(t => t.jukeboxIndex === trackIndex);

        if (!track) {
            log.warn('Track id not found for: ', trackIndex);
        }

        return track;
    }

    private handleVoteUpCommand(voterId: string, trackIndex: number, voteCount: number = 1) {
        if (!this.canVote(voterId, true)) {
            log.debug("user has exceeded maximum concurrent votes");
            return;
        }

        const track = this.getTrackByIndex(trackIndex);
        if (!track) {
            return;
        }

        if (track.voters.up.find(v => v === voterId)) {
            return log.warn("Ignoring command, user already voted", voterId, track);
        }

        const newTrack = {...track,
            lastVoteTimestamp: new Date().getTime(),
            totalVotes: (track.totalVotes || 0) + 1
        };
        newTrack.voters.up = newTrack.voters.up.concat(voterId);

        if (newTrack.pendingAdd) {
            // others can approve the track
            if (voterId !== newTrack.addedBy) {
                log.debug("approving track");
                newTrack.pendingAdd = false;
            }
        }

        this._controller.syncController.updateTrack(newTrack.syncIndex, newTrack);

        this.sendEvent({
            trackSyncIndex: newTrack.syncIndex,
            type: SyncEventType.VoteUp
        });

        log.debug("Added vote for track: ", track);
    }

    private handleVoteDownCommand(voterId: string, trackIndex: number, voteCount: number = 1) {
        if (!this.canVote(voterId, false)) {
            log.debug("user has exceeded maximum concurrent votes");
            return;
        }

        const track = this.getTrackByIndex(trackIndex);

        if (track.voters.down.find(v => v === voterId)) {
            return log.warn("Ignoring command, user already voted", voterId, track);
        }

        const newTrack = {...track,
            lastVoteTimestamp: new Date().getTime(),
            totalVotes: (track.totalVotes || 0) + 1
        };
        newTrack.voters.down = newTrack.voters.down.concat(voterId);

        this._controller.syncController.updateTrack(newTrack.syncIndex, newTrack);
        this.sendEvent({
            trackSyncIndex: newTrack.syncIndex,
            type: SyncEventType.VoteDown
        });

        log.debug("Added vote for track: ", track);
    }

    private sendEvent(event: SyncEvent) {
        if (!this._initialized) {
            return;
        }

        this._controller.syncController.sendEvent(event);
    }

    private handleAddTrackToJukeboxCommand(voterId: string, searchQuery: string) {
        if (!this.canVote(voterId, true)) {
            log.debug("user has exceeded maximum concurrent votes");
            return;
        }

        const getTrack = searchQuery.startsWith("spotify:track:") ?
            this._controller.spotifyApi.getTrack(searchQuery)
            : this._controller.spotifyApi.search(searchQuery).then(response => response.data.tracks.items.length ? response.data.tracks.items[0] : null);

        getTrack
        .then(item => {
            if (!item || !item.is_playable) {
                log.debug("track not found from spotify:", searchQuery, item);
                return;
            }

            const existingTrack = this._controller.tracks.find(t => t.spotifyId === item.uri);
            let existingTrackIndex = existingTrack ? existingTrack.syncIndex : - 1;

            if (existingTrackIndex >= 0) {
                log.debug("adding vote. found existing track", existingTrackIndex, 'for', searchQuery);
                this.handleVoteUpCommand(voterId, existingTrackIndex + 1);
                return;
            }

            const score = this.getSearchScore(searchQuery, item.artists[0].name, item.name);
            log.debug("got score", score, 'for', item);


            // make sure we don't have the same found track in playlist
            existingTrackIndex = this.findExistingTrackIndexByString(item.artists[0].name + ' ' + item.name, SCORE_THRESHOLD_FOR_ADD);
            if (existingTrackIndex < 0) {
                const newTrack: Track = {
                    artist: item.artists[0].name,
                    title: item.name,
                    addedBy: voterId,
                    addedTimestamp: new Date().getTime(),
                    jukeboxIndex: this._controller.getNewTrackIndex(),
                    syncIndex: undefined,
                    spotifyId: item.uri,
                    lastVotesUp: 0,
                    lastVotesDown: 0,
                    lastPlayedTimestamp: 0,
                    lastVoteTimestamp: new Date().getTime(),
                    totalVotes: 1,
                    image: !!item.album.images && item.album.images.length > 0 ? item.album.images[0] : undefined,
                    voters: {
                        up: [voterId],
                        down: []
                    },
                    pendingAdd: true,
                };

                this._controller.syncController.addTrack(newTrack)
                .then((listItem) => {
                    this.sendEvent({
                        trackSyncIndex: listItem.index,
                        type: SyncEventType.NewTrack
                    });
                })
            }
            else {
                this.handleVoteUpCommand(voterId, existingTrackIndex + 1);
                log.debug('found existing track, adding vote', existingTrackIndex );
            }
        })
        .catch(err => {
            log.error(err);
        })
    }

    private canVote(user: string, up: boolean): boolean {
        return getUserVoteCount(user, up, this._controller.tracks) < getMaxVotesPerUser(this._controller.jukebox);
    }
}