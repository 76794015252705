import { JukeboxMeta } from "../server/src/types/api";
import { SyncController } from "./SyncController";
import { JukeboxController } from "./JukeboxController";

export interface JukeboxInfo {
    phoneNumber?: string;
    playlistName: string;
    status: JukeboxStatus;
    currentTrack?: Track;
    upcomingTrack?: Track;
    owner: string;
    shortUrl: string;
    lastTrackIndex: number;
    controllerId?: string;
    trackExpirationInSeconds?: number;
    maxVotesPerUser?: number;
    spotifyTracksUrl?: string;
    logoUrl?: string;
    brandColor?: string;
}

export type Tracks = Array<Track>;

export interface Track {
    addedBy?: string;
    addedTimestamp: number;
    jukeboxIndex: number;
    syncIndex: number;
    spotifyId: string;
    lastVotesUp: number;
    lastVotesDown: number;
    artist: string;
    title: string;
    lastPlayedTimestamp: number;
    lastVoteTimestamp: number;
    totalVotes: number;
    image: {
        url: string;
    };
    voters: {
        up: Array<string>;
        down: Array<string>;
    };
    linked_from_uri?: string;
    pendingAdd?: boolean;
}

export enum SyncEventType {
    VoteUp = "VOTE_UP",
    VoteDown = "VOTE_DOWN",
    NextTrack = "NEXT_TRACK",
    NewTrack = "NEW_TRACK",
    RemovedTrack = "REMOVE_TRACK"
}

export interface SyncEvent {
    readonly type: SyncEventType;
    readonly trackSyncIndex: number;
    sid?: string;
}

export interface TracksState {
    readonly tracks: Tracks;
}

export interface PlaybackInfo {
    readonly playing: boolean;
    readonly repeat: boolean;
    readonly shuffle: boolean;
    readonly progressMs: number;
    readonly durationMs: number;
    readonly track: Track;
}

export enum JukeboxStatus {
    NotReady = "NotReady", // do not have track info yet
    Ready = "Ready", // tracks loaded
    Running = "Running", // running
    Paused = "Paused", // paused
    StoppedAuto = "Initialized" // marked as stopped by webhook that detected disconnect
}

export interface DeviceInfo {
    readonly id: string;
    readonly is_active: boolean;
    readonly is_restricted: boolean;
    readonly name: string;
    readonly type: "Computer" | "Smartphone" | "Speaker";
}

export interface SpotifyState {
    readonly connected: boolean;
    readonly playbackInfo?: PlaybackInfo;
    readonly deviceInfo?: DeviceInfo;
}

export interface AsyncDataType<T> {
    readonly isPending: boolean;
    readonly error?: Error;
    readonly data?: T;
}

export interface AsyncUserState extends AsyncDataType<{ _id: string }> {}
export interface AsyncJukeboxesState extends AsyncDataType<Array<JukeboxMeta>> {}
export interface AsyncVoterState extends AsyncDataType<string> {}

export interface AccountState {
    readonly user: AsyncUserState;
    readonly jukeboxes: AsyncJukeboxesState;
    readonly voterId: AsyncVoterState;
}

export interface AppState {
    syncController?: SyncController;
    jukeboxController?: JukeboxController;
}

export interface StoreType {
    readonly app: AppState;
    readonly account: AccountState;
    readonly jukebox: JukeboxInfo;
    readonly tracks: TracksState;
    readonly spotifyState: SpotifyState;
}
