import styled from '@emotion/styled';

export const Container = styled("div")`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const FlexColumn = styled("div")`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const ListContainer = styled("div")`
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: center;

    width: 64vw;
`;

export const Background = styled("div")`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    z-index: -1;
`;

export const BackgroundOverlay = styled("div")`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000000;
    opacity: 0.8;
`;