import { injectGlobal } from "@emotion/css";

injectGlobal`
body, #root {
    display: flex;
    flex-direction: column;
    margin: 0px;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    color: #fff;
    scrollbar-base-color: #181818;
    scrollbar-face-color:#404040;
    scrollbar-3dlight-color: #181818;
    scrollbar-highlight-color: #404040;
    scrollbar-track-color:#181818;
    scrollbar-arrow-color:#404040;
    scrollbar-shadow-color:#181818;
    scrollbar-darkshadow-color: #181818;
    scrollbar-color: #181818 #181818;
    scrollbar-width: thin;
    -webkit-overflow-scrolling: touch;
}

ul {
    scrollbar-color: #181818 #181818;
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    width: 10px;
    background: #181818;
}

::-webkit-scrollbar-button {
    display: none
}

::-webkit-resizer {
    display: none
}

::-webkit-scrollbar-corner {
    display: none
}

::-webkit-scrollbar-track {
    background: transparent
}

::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: #404040;
    border: 2px solid #181818
}
`;