import * as React from "react";
import { connect } from "react-redux";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";

import { JukeboxInfo, StoreType } from "../types";

import { PopupMenu } from "../components/PopupMenu";

import { PlaybackControls } from "./PlaybackControls";
import {
    TopTrackInfoWithVoteTimer,
    HeaderContainer,
    JukeboxLogo
} from "./NavBar.Components";
import { redirectToLogin } from "../actions";


export interface Props {
    isLoggedIn: boolean;
    jukebox: JukeboxInfo;
}
interface State {
    controlsHidden?: boolean;
}

class NavBar extends React.PureComponent<Props, State> {
    state: State = {};

    componentDidMount() {
        document.addEventListener("fullscreenchange", this.handleFullScreenChange);
        document.addEventListener("mousemove", this.handleMouseMove);
    }

    componentWillUnmount() {
        document.removeEventListener("fullscreenchange", this.handleFullScreenChange);
        document.removeEventListener("mousemove", this.handleMouseMove);
    }

    render() {
        const { isLoggedIn, jukebox } = this.props;

        return (
            <HeaderContainer className="top-bar">
                <AppBar color="inherit" position="relative">
                    <Toolbar>
                        <JukeboxLogo jukebox={jukebox} />
                        <TopTrackInfoWithVoteTimer />
                        {!this.state.controlsHidden && (
                            <>
                                {!isLoggedIn && <Link onClick={this.onLoginClick}>Log in / Sign up</Link>}
                                <PlaybackControls />
                                <PopupMenu isLoggedIn={isLoggedIn} />
                            </>
                        )}
                    </Toolbar>
                </AppBar>
            </HeaderContainer>
        );
    }

    private handleFullScreenChange = () => {
        this.initHideTimer();
    };

    private handleMouseMove = () => {
        if (this.state.controlsHidden && !this._hideTimeout) {
            this.initHideTimer();
            this.setState({
                controlsHidden: false
            });
        }
    };

    private _hideTimeout: any;
    private initHideTimer() {
        if (!document.fullscreenElement) return;

        if (this._hideTimeout) {
            clearTimeout(this._hideTimeout);
        }

        this._hideTimeout = setTimeout(() => {
            this.setState({
                controlsHidden: true
            });
            this._hideTimeout = 0;
        }, 5000);
    }

    private onLoginClick = () => {
        redirectToLogin();
    };
}

const mapStateToProps = (state: StoreType): Props => {
    return {
        isLoggedIn: !!state.account.user.data || state.account.user.isPending,
        jukebox: state.jukebox
    };
};
export default connect(mapStateToProps)(NavBar);