import { Client as SyncClient, SyncDocument, SyncList, SyncListItem, SyncStream, Paginator } from "twilio-sync";

import { Track, Tracks, JukeboxStatus, JukeboxInfo, SyncEvent } from "./types";
import * as Actions from "./actions";

import { JukeboxClient } from "./JukeboxClient";
import log from "./log";
import { EventEmitter } from "events";
import { SaveJukeboxStepData } from "./views/SetupView/types";

const syncClientOptions = {
    Sync: {
        enableSessionStorage: false
    }
};

export interface TrackVotes {
    readonly upTotal: number;
    readonly downTotal: number;
}

export class SyncController extends EventEmitter {

    private static _activeJukeboxId: string = '';

    public static get(jukeboxID: string, isForGuest: boolean = false): Promise<SyncController> {
        this._activeJukeboxId = jukeboxID;
        return JukeboxClient.instance
            .getSyncToken(jukeboxID, isForGuest)
            .then((token) => {
                const controller = new SyncController(new SyncClient(token, syncClientOptions), jukeboxID, isForGuest);
                return controller.initialize().then(() => controller);
            })
            .catch((err) => {
                log.error("Unable to create sync controller", err);
                throw err;
            });
    }

    private _syncClient: SyncClient;

    private _commandList: SyncList;
    private _trackList: SyncList;
    private _jukeboxDocument: SyncDocument;
    private _eventStream: SyncStream;
    private _pendingCommands: Array<SyncListItem> = [];
    private _wasConnected = false;

    private _jukeboxId: string;

    private _onCommand: (command: string, from: string) => void;

    public set onCommand(value: (command: string, from: string) => void) {
        this._onCommand = value;
    }

    private _connectionCheckTimeout: any;
    private constructor(syncClient: SyncClient, jukeboxId: string, isForGuest: boolean) {
        super();
        this._syncClient = syncClient;
        this._jukeboxId = jukeboxId;
        log.debug("new sync controller");

        const updateToken = () =>
            JukeboxClient.instance
                .getSyncToken(this._jukeboxId, isForGuest)
                .then((token) => {
                    log.debug("setting sync token");
                    return syncClient.updateToken(token);
                })
                .catch((err) => {
                    log.error("failed fetch and set new sync token", err);
                    throw err;
                })
                .then(() => {
                    log.debug("sync token updated successfully");
                });

        syncClient.on("tokenAboutToExpire", () => {
            log.debug("Sync token about to expire");
            updateToken();
        });
        syncClient.on("tokenExpired", () => {
            log.warn("Sync token expired");
            updateToken().catch(() => {
                this.emit("tokenExpired");
            });
        });
        syncClient.on("connectionStateChanged", async (cs) => {
            log.info("Sync connectionStateChanged", cs);

            // if we are not connected, but were previously connected - recover
            if (["disconnected", "denied", "error", "connecting"].indexOf(cs) >= 0) {
                if (this._wasConnected) {
                    clearTimeout(this._connectionCheckTimeout);
                    // wait for 10s
                    await new Promise((resolve) => setTimeout(resolve, 10000));
                    // if connected then nothing to do
                    if (syncClient.connectionState === "connected") return;

                    // check again in 60s
                    this._connectionCheckTimeout = setTimeout(() => {
                        const cs = syncClient.connectionState;
                        log.info("Checking Sync connectionState", cs);
                        if (cs !== "connected") {
                            log.error("Sync connection lost and not recovered");
                            this.emit("disconnected");
                        }
                    }, 60000);

                    // but try to update token and check again
                    try {
                        log.debug("Updating token from recovery");
                        await updateToken();
                    } catch (e) {
                        log.debug("failed to update token and recover");
                    }
                }
            } else if (cs === "connected") {
                clearTimeout(this._connectionCheckTimeout);
                this._wasConnected = true;
                this.emit("connected");
            }
        });

        // if window become visible, e.g. on phone, then make sure to check for new token
        window.document.addEventListener("visibilitychange", () => {
            log.debug(
                `visibilitychange, hidden=${window.document.hidden} connectionState=${syncClient.connectionState}`
            );
            // make sure we have up to date token
            if (!window.document.hidden) {
                if (["connecting", "connected"].indexOf(syncClient.connectionState) < -1) {
                    updateToken();
                }
            }
        });
    }

    private initialize(): Promise<any> {
        const initPromise = this._syncClient.document("jukebox-" + this._jukeboxId).then((document) => {
            this._jukeboxDocument = document;
            this._jukeboxDocument.on("updated", this.onJukeboxUpdated);
            // this._jukeboxDocument.on('removed', this.onJukeboxRemoved);
            Actions.setJukeboxInfo(document.data as JukeboxInfo);
        });

        const tracksPromise = this._syncClient.list("tracks-" + this._jukeboxId).then((list) => {
            this._trackList = list;
            this._trackList.on("itemAdded", this.onTrackAdded);
            this._trackList.on("itemUpdated", this.onTrackUpdated);
            this._trackList.on("itemRemoved", this.onTrackRemoved);
            this._trackList.on("removed", this.onTracksRemoved);
        });

        const eventsPromise = this._syncClient
            .stream("events-" + this._jukeboxId)
            .then((stream) => {
                this._eventStream = stream;
                stream.on("messagePublished", this.onEventPublished);
            })
            .catch((e) => {
                log.debug("Stream not found for jukebox", this._jukeboxId);
            });

        return Promise.all([initPromise, tracksPromise, eventsPromise]);
    }

    private _processCommandsTimer: any = 0;
    private startProcessingCommands() {
        clearTimeout(this._processCommandsTimer);
        setTimeout(() => {
            this.processCommandsBatch();
            this.startProcessingCommands();
        }, 1000);
    }

    private processCommandsBatch = async () => {
        const commandLength = this._pendingCommands.length;
        if (commandLength > 0) {
            log.debug("processing command batch", commandLength);
        }

        const MAX_COMMANDS = 16;
        for (let i = 0; i < Math.min(MAX_COMMANDS, commandLength); i++) {
            const command = this._pendingCommands.shift();
            this.processCommand(command);
        }
    };

    public async initController(): Promise<void> {
        return this._syncClient
            .list("commands-" + this._jukeboxId)
            .then((list) => {
                this._commandList = list;

                return this.processCommands();
            })
            .then(() => {
                this._commandList.on("itemAdded", this.onCommandAdded);
                this.startProcessingCommands();
            })
            .catch(() => log.debug("No access to commands"));
    }

    public cleanup() {
        log.debug("cleanup")

        if (SyncController._activeJukeboxId === this._jukeboxId) {
            log.debug("not cleaning up, still active");
            return;
        }
        if (this._syncClient) {
            return this._syncClient.shutdown();
        }

        return Promise.resolve();
    }

    public setupJukebox(
        jukeboxInfo: SaveJukeboxStepData,
        owner: string,
        tracks: Array<Track>,
        shortUrl: string,
        phoneNumber: string,
        lastTrackIndex: number,
        spotifyTracksUrl: string
    ) {
        const jukeboxState: JukeboxInfo = {
            currentTrack: undefined,
            upcomingTrack: undefined,
            playlistName: jukeboxInfo.name,
            brandColor: jukeboxInfo.brandColor,
            logoUrl: jukeboxInfo.logoUrl,
            status: JukeboxStatus.NotReady,
            owner,
            shortUrl,
            phoneNumber,
            lastTrackIndex,
            spotifyTracksUrl
        };

        Actions.setTracks([]);

        const BATCH_SIZE = 20;

        return Promise.all([
            this._jukeboxDocument.mutate((value) => ({ ...value, ...jukeboxState })),
            // TODO: process sequentially to preserve order
            Promise.all(
                tracks.map((track, index) => {
                    return new Promise<void>((resolve, reject) => {
                        const batch = Math.floor(index / BATCH_SIZE);

                        const timeoutTimer = setTimeout(() => {
                            this._trackList
                                .push(track)
                                .then(() => resolve())
                                .catch((e) => {
                                    log.error("unable to save track", e);
                                    reject();
                                })
                                .then(() => {
                                    clearTimeout(timeoutTimer);
                                });
                        }, batch * 1000);
                    });
                })
            )
        ]);
    }

    public deleteTracksBySyncIndexes(syncIndexes: Set<number>) {
        return Promise.all(
            Array.from(syncIndexes).map((syncIndex) => {
                return typeof syncIndex === "number" ? this._trackList.remove(syncIndex) : Promise.resolve();
            })
        );
    }

    public resetVotesBySyncIndexes(syncIndexes: Set<number>) {
        const resetTracks = (track: Track) => {
            if (track.voters.up.length === 0 && track.voters.up.length === 0) return track;

            return {
                ...track,
                voters: {
                    up: [],
                    down: []
                }
            };
        };

        return Promise.all(
            Array.from(syncIndexes).map((syncIndex) => {
                return syncIndex ? this._trackList.mutate(syncIndex, resetTracks) : Promise.resolve(null);
            })
        );
    }

    public getJukeboxDocument(): SyncDocument {
        return this._jukeboxDocument;
    }

    public getJukeboxInfo(): JukeboxInfo {
        return this._jukeboxDocument ? (this._jukeboxDocument.data as JukeboxInfo) : undefined;
    }

    public setJukeboxInfo(value: JukeboxInfo) {
        Actions.setJukeboxInfo(value);
        return this._jukeboxDocument.update(value);
    }

    public loadTracks() {
        return this.getTracks().then((tracks) => {
            Actions.setTracks(tracks);
        });
    }

    private getTracks(): Promise<Tracks> {
        const tracks = new Array<Track>();

        const processPaginator = (paginator: Paginator<SyncListItem>) => {
            paginator.items.forEach((item) => {
                const track = item.data as Track;
                track.syncIndex = item.index;
                tracks.push(track);
            });

            if (paginator.hasNextPage) {
                return paginator.nextPage().then((nextPaginator) => {
                    return processPaginator(nextPaginator);
                });
            }

            return tracks;
        };

        return this._trackList.getItems({ from: 0, pageSize: 100, order: "asc", limit: 1000 }).then((paginator) => {
            return processPaginator(paginator);
        });
    }

    public addTrack(track: Track) {
        return this._trackList.push(track);
    }

    private onJukeboxUpdated = (args: { data: JukeboxInfo; isLocal: boolean }) => {
        if (args.isLocal) {
            return;
        }

        Actions.setJukeboxInfo(args.data);
    };

    private onTrackAdded = (args: { item: SyncListItem; isLocal: boolean }) => {
        log.debug("track added: ", args.item);

        const track = args.item.data as Track;
        track.syncIndex = args.item.index;

        Actions.addTrack(track);
    };

    private onTrackUpdated = (args: { item: SyncListItem; isLocal: boolean }) => {
        log.debug("track updated: ", args.item);

        const track = args.item.data as Track;
        track.syncIndex = args.item.index;

        Actions.updateTrack(track);
    };

    private onTracksRemoved = (args: { isLocal: boolean }) => {
        log.debug("tracks removed: ");

        Actions.setTracks([]);
    };

    private onTrackRemoved = (args: { index: number; isLocal: boolean }) => {
        log.debug("track removed: ", args.index);

        Actions.removeTrack(args.index);
    };

    private onCommandAdded = (args: { item: SyncListItem }) => {
        log.debug("Command added: ", args.item.index, args.item.data);
        this._pendingCommands.push(args.item);
    };

    private onEventPublished = (args: { message: { sid: string; data: SyncEvent}; isLocal: boolean }) => {
        const eventVal = args.message.data;
        eventVal.sid = args.message.sid;

        this.emit("syncEvent", eventVal);
    };

    private async processCommands() {
        log.debug("processing commands");
        await this._commandList
            .getItems({ from: 0, pageSize: 30, order: "asc", limit: 1000 })
            .then((paginator) => this.processCommandPaginator(paginator));

        while (this._pendingCommands.length > 0) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            await this.processCommandsBatch();
        }
        log.debug("processing commands done");
    }

    private async processCommandPaginator(paginator: Paginator<SyncListItem>) {
        paginator.items.forEach((item) => {
            this._pendingCommands.push(item);
        });

        if (paginator.hasNextPage) {
            await new Promise((resolve) => setTimeout(resolve, 50));
            return paginator.nextPage().then((nextPaginator) => this.processCommandPaginator(nextPaginator));
        }
    }

    private processCommand(item: SyncListItem) {
        try {
            if (this._onCommand) {
                log.debug("Processing command: ", item.data);
                const val = item.data as any;
                this._onCommand(val.data, val.from);
                this._commandList.remove(item.index);
            }
        } catch (err) {
            log.error(err);
        }
    }

    public addCommand(command: string) {
        if (!this._commandList) {
            log.warn("Command list not ready");
            return;
        }
        return this._commandList.push({ data: command, from: this._jukeboxDocument.data["owner"] });
    }

    public sendEvent(event: SyncEvent) {
        return this._eventStream.publishMessage(event);
    }

    public updateTrack(trackIndex: number, value: Track) {
        return this._trackList.set(trackIndex, value);
    }

    public removeTrack(trackIndex: number) {
        return this._trackList.remove(trackIndex);
    }
}
