import axios, { AxiosInstance } from 'axios';
import { SERVICE_BASE_URL } from './config';
import { applyAxiosRetry } from './applyAxiosRetry';

import { AuthToken, JukeboxMeta, Vote, SearchResults } from '../server/src/types/api'
import log from "./log";
import * as Actions from "./actions";

const Fingerprint2 = require("fingerprintjs2");

export class JukeboxClient {

    private static _instance: JukeboxClient;

    public static get instance() {
        if (!this._instance) {
            this._instance = new JukeboxClient();
        }

        return this._instance;
    }

    private _axios: AxiosInstance;

    constructor() {
        this._axios = axios.create({ baseURL: SERVICE_BASE_URL, withCredentials: true });
        applyAxiosRetry(this._axios);
    }

    public logOut() {
        return this._axios.get('/logout')
            .then(() => {
                Actions.getAccountInfo();
            });
    }

    public spotifySignIn() {
        window.location.href = SERVICE_BASE_URL + 'auth/spotify';
    }

    public getAccountInfo() {
        return this._axios.get('/account/info')
            .then(response => {
                return response.data;
            })
            .catch(() => {
                log.debug("Not logged in");

                return undefined;
            })
    }

    public createJukebox(jb: JukeboxMeta): Promise<JukeboxMeta> {
        return this._axios.post("/jukebox", jb)
            .then(response => response.data as JukeboxMeta);
    }

    public updateJukebox(jb: JukeboxMeta): Promise<JukeboxMeta> {
        return this._axios.put("/jukebox/" + jb._id, jb)
            .then(response => response.data as JukeboxMeta);
    }

    public getJukeboxes() {
        return this._axios.get("/jukebox")
            .then(response => response.data as Array<JukeboxMeta>);
    }

    public deleteJukebox(jukeboxId: string) {
        return this._axios.delete("/jukebox/" + jukeboxId);
    }

    // public updateJukebox(jukeboxId: string) {
    //     return this._axios.put("/jukebox/" + jukeboxId);
    // }

    public getJukebox(jukeboxId: string) {
        return this._axios.get("/jukebox/" + jukeboxId);
    }

    public getPhoneNumber(jukeboxId: string, country: string = "EE") {
        interface Number {
            number: string;
        }

        return this._axios.post("/jukebox/" + jukeboxId + "/phone", { country })
            .then(response => response.data as Number);
    }

    public getSyncToken(jukeboxId: string, isForGuest: boolean = false): Promise<string> {
        log.debug("fetching new sync token for", jukeboxId, isForGuest);
        return this._axios.get("/jukebox/" + jukeboxId + "/token", { params: { isForGuest }})
            .then(response => response.data.token);
    }

    public getSpotifyToken() {
        return this._axios.get("/auth/spotify/token")
            .then(response => response.data as AuthToken);
    }

    public getVoterId(jukeboxId: string): Promise<string> {
        return this.getOrCreateVoterIdFromLocalStorage()
            .then(voterId => {
                this.storeVoterId(voterId);
                return voterId;
            });
    }

    private readonly KEY = "VOTER_ID";
    private getOrCreateVoterIdFromLocalStorage(): Promise<string> {

        let value: string = undefined;
        try {
            value = window.localStorage.getItem(this.KEY);

            if (value) {
                return Promise.resolve(value);
            }
        }
        catch (e) {
            log.warn("Unable to get voterId from localStorage");
        }

        if (!value) {
            return this.createVoterId()
                .then(voterId => {
                    this.storeVoterId(voterId);
                    return voterId;
                });
        }
    }

    private storeVoterId(voterId: string) {
        try {
            window.localStorage.setItem(this.KEY, voterId);
        }
        catch (e) {
            log.warn("Failed to store voterId")
        }
    }

    private createVoterId(): Promise<string> {
        return new Promise((resolve, reject) => {
            try {
                new Fingerprint2().get((result, components) => {
                    resolve(result)
                    log.debug(result) // a hash, representing your device fingerprint
                    log.debug(components) // an array of FP components
                })
            }
            catch (e) {
                log.error("Unable to get fingerprint", e);
                reject("Unable to get fingerprint")
            }
        });
    }

    public postVote(jukeboxId: string, trackIndex: number, up: boolean) {
        return this.getOrCreateVoterIdFromLocalStorage()
            .then(fp => {
                const payload: Vote = {
                    trackIndex,
                    vote: up ? "up" : "down",
                    fp
                };

                return this._axios.post("/jukebox/"+ jukeboxId + "/vote", payload);
            })
            .then(response => this.saveVoterIdFromResponse(response.data));
    }

    public addTrack(jukeboxId: string, trackNameOrUri: string) {
        return this.getOrCreateVoterIdFromLocalStorage()
        .then(fp => {
            const track = {
                track: trackNameOrUri,
                fp
            };
            return this._axios.post("/jukebox/"+ jukeboxId + "/add", track)
                .then(response => this.saveVoterIdFromResponse(response.data));
        });
    }

    private saveVoterIdFromResponse({ voterId }) {
        if (voterId) {
            this.storeVoterId(voterId);
            Actions.setVoterId(voterId);
        }
    }

    public getSearchResults(jukeboxId: string, owner: string, query: string): Promise<SearchResults> {
        return this._axios.post("/jukebox/"+ jukeboxId + "/search/" + owner, undefined,
            {
                params: { q: query }
            }
        ).then(response => response.data);
    }
}
