import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import * as Actions from './actions'
import { RootComponent } from './RootComponent';

import log from "./log";
import defaultLog from "loglevel";
import { store } from './store';
import './sentry';
import './globalStyle';


class JBImpl {
    public init(containerId: string = "root") {
        Actions.getAccountInfo();
        Actions.getVoterId("ANY");

        if (!window.location.href.match(/localhost/)) {
            // TODO refactor
            ReactGA.initialize('UA-139806978-1');
        }

        const container = document.getElementById(containerId);
        const root = createRoot(container); // createRoot(container!) if you use TypeScript
        root.render(
            <BrowserRouter>
                <RootComponent />
            </BrowserRouter>,
        );
    }

    public get log() {
        return log;
    }

    public get syncLog() {
        return defaultLog;
    }

    public get store() {
        return store;
    }
}

export const JB = new JBImpl();