import log from "loglevel";
import logPrefix from 'loglevel-plugin-prefix';

const logger = log.getLogger("JB");

const defaults = {
    template: '[%t] %l:',
    levelFormatter: function (level) {
        return level.toUpperCase();
    },
    nameFormatter: function (name) {
        return name || 'root';
    },
    timestampFormatter: function (date: Date) {
        return date.toISOString().substr(11, 12);
    },
    format: undefined
  };

logger.setDefaultLevel("warn");
logPrefix.reg(log);
logPrefix.apply(logger, defaults);

export default logger;

