import * as React from 'react'

const DEFAULT_DEBOUNCE_MS = 1000;

interface Props {
    interval: number;
    children: () => React.ReactElement<any>;
    innerProps: any;
}

interface State {
    lastUpdateTime: number;
}

export class DebouncedRender extends React.Component<Props, State> {

    static defaultProps: Partial<Props> = {
        interval: DEFAULT_DEBOUNCE_MS
    }

    private _renderTimer: any = 0;

    state: State = {
        lastUpdateTime: 0
    }

    shouldComponentUpdate(nextProps: Props) {
        const changed = nextProps.innerProps !== this.props.innerProps;
        if (changed) {
            if (Date.now() - this.state.lastUpdateTime > this.props.interval) {
                this.updateState();
                return true;
            }
            else {
                this.scheduleUpdate();
            }

            return false;
        }

        return true;
    }

    componentWillUnmount() {
        clearInterval(this._renderTimer);
    }

    render() {
        return this.props.children();
    }

    private scheduleUpdate() {
        if (this._renderTimer !== 0)
            return;

        this._renderTimer = setTimeout(() => {
            this.updateState();
            this._renderTimer = 0;
        }, this.props.interval);
    }

    private updateState() {
        this.setState(state => ({...state, lastUpdateTime: Date.now() }));
    }
}

