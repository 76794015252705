import * as React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Fab from '@mui/material/Fab';
import Icon from '@mui/material/Icon';

import { Track, Tracks, JukeboxInfo, JukeboxStatus } from '../types'

import { PlaylistTracks } from '../components/PlaylistTracks'
import { JukeboxClient } from '../JukeboxClient';
import styled from '@emotion/styled';
import { FlexColumn, Background, BackgroundOverlay } from '../components/Common';
import { Typography } from '@mui/material';
import { TrackSearchComponent } from '../components/TrackSearchComponent';
import { isPendingRemoval } from '../TrackHelper';
import { getUserVoteCount, getMaxVotesPerUser } from '../JukeboxHelper';
import { JukeboxThemeProvider } from '../components/JukeboxThemeProvider';
import { JukeboxLogo } from '../components/NavBar.Components';

interface Props {
    jukebox: JukeboxInfo;
    tracks: Tracks;
    phoneNumber: string;
    jukeboxId: string;
    voterId: string;
}

interface State {
    isDialogOpen: boolean;
    selectedTrackUri?: string;
    canVoteUp: boolean;
    canVoteDown: boolean;
}

export class MinimalJukeboxView extends React.PureComponent<Props, State> {

    state: State = {
        isDialogOpen: false,
        selectedTrackUri: undefined,
        canVoteUp: true,
        canVoteDown: true
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State): State {
        const canVoteUp = getUserVoteCount(nextProps.voterId, true, nextProps.tracks) < getMaxVotesPerUser(nextProps.jukebox);
        const canVoteDown = getUserVoteCount(nextProps.voterId, false, nextProps.tracks) < getMaxVotesPerUser(nextProps.jukebox);

        if (canVoteUp !== prevState.canVoteUp || canVoteDown !== prevState.canVoteDown) {
            return {...prevState,
                canVoteUp,
                canVoteDown
            };
        }

        return null;
    }

    render() {
        const { jukebox, tracks } = this.props;
        const dialogActions = [
            {
                label: "Close", onClick: this.onHandleCloseDialog, disabled: false
            }
        ];

        const trackInfo = jukebox.currentTrack || { image: undefined };
        const bgStyle = {
            background: !!trackInfo.image ? "url('" + trackInfo.image.url + "')" : undefined
        }
        const upcomingTrackId = jukebox.upcomingTrack && jukebox.upcomingTrack.spotifyId;
        const activeTracks = tracks.filter(track => !isPendingRemoval(track) || track.spotifyId === upcomingTrackId);

        const playingTrack = jukebox.currentTrack;
        const upcomingTrack = jukebox.upcomingTrack ? activeTracks.find(t => t.syncIndex === jukebox.upcomingTrack.syncIndex) : null;

        let nextTrack: Track;
        let playlistTracks: Track[];
        if (!upcomingTrack) {
            nextTrack = activeTracks[0];
            playlistTracks = activeTracks.slice(1);
        }
        else {
            nextTrack = upcomingTrack;
            playlistTracks = activeTracks;
        }

        const pendingTracks = tracks.filter(track => isPendingRemoval(track));
        return (
            <JukeboxThemeProvider jukebox={jukebox}>
                <Container>
                    <Background className='right-background' style={bgStyle}>
                        <BackgroundOverlay className='right-background-overlay' />
                    </Background>
                    <JukeboxLogo jukebox={jukebox} />
                    {(jukebox.status !== JukeboxStatus.Running) && (
                        <Typography variant="h4" color="primary" gutterBottom>
                            Jukebox not running
                        </Typography>
                    )}
                    {(jukebox.status !== JukeboxStatus.Running) && (
                        <Typography variant="subtitle2" color="primary" gutterBottom>
                            Voting disabled
                        </Typography>
                    )}
                    {(!this.state.canVoteUp) && (
                        <Typography variant="h4" color="primary" gutterBottom>
                            Maximum number of active "up" votes reached ({getMaxVotesPerUser(jukebox)})
                        </Typography>
                    )}
                    {(!this.state.canVoteDown) && (
                        <Typography variant="h4" color="primary" gutterBottom>
                            Maximum number of active "down" votes reached ({getMaxVotesPerUser(jukebox)})
                        </Typography>
                    )}
                    <div className='add-button'>
                        <Fab
                            variant="extended"
                            onClick={this.handleAddClick}
                            color="primary"
                            size="small"
                            disabled={!this.state.canVoteUp || jukebox.status !== JukeboxStatus.Running}
                        >
                            <Icon>add_circle_outline</Icon>
                            Add track
                        </Fab>
                    </div>
                    <div className='tracklist'>
                        {playingTrack &&
                            <>
                                <SubTitle>
                                    Now playing
                                </SubTitle>
                                <PlaylistTracks
                                    tracks={[ playingTrack ]}
                                    jukebox={jukebox}
                                    showVoteButtons={jukebox.status === JukeboxStatus.Running}
                                    disableVoteUpButtons={!this.state.canVoteUp}
                                    disableVoteDownButtons={!this.state.canVoteDown}
                                    jukeboxId={this.props.jukeboxId}
                                    minimal
                                    noTrackNumbers
                                />
                            </>
                        }
                        {nextTrack &&
                            <>
                                <SubTitle>
                                    Next
                                </SubTitle>
                                <PlaylistTracks
                                    tracks={[ nextTrack ]}
                                    jukebox={jukebox}
                                    showVoteButtons={jukebox.status === JukeboxStatus.Running}
                                    disableVoteUpButtons={!this.state.canVoteUp}
                                    disableVoteDownButtons={!this.state.canVoteDown}
                                    jukeboxId={this.props.jukeboxId}
                                    minimal
                                    noTrackNumbers
                                />
                            </>
                        }
                        <SubTitle>
                            Coming Up
                        </SubTitle>
                        <PlaylistTracks
                            tracks={playlistTracks}
                            jukebox={jukebox}
                            showVoteButtons={jukebox.status === JukeboxStatus.Running}
                            disableVoteUpButtons={!this.state.canVoteUp}
                            disableVoteDownButtons={!this.state.canVoteDown}
                            jukeboxId={this.props.jukeboxId}
                            minimal
                            noTrackNumbers
                        />
                        {pendingTracks.length > 0 &&
                            <>
                                <SubTitle>
                                    <Icon color="primary">
                                        repeat
                                    </Icon>
                                    Pending add and removal
                                </SubTitle>
                                <PlaylistTracks
                                    tracks={pendingTracks}
                                    jukebox={jukebox}
                                    showVoteButtons={jukebox.status === JukeboxStatus.Running}
                                    disableVoteUpButtons={!this.state.canVoteUp}
                                    disableVoteDownButtons={!this.state.canVoteDown}
                                    jukeboxId={this.props.jukeboxId}
                                    minimal
                                    noTrackNumbers
                                />
                            </>
                        }
                    </div>
                    <Dialog
                        fullWidth
                        open={this.state.isDialogOpen}
                        onClose={this.onHandleCloseDialog}
                        aria-labelledby="form-dialog-title"
                    >
                        <StyledDialogContent>
                            <TrackSearchComponent
                                jukeboxId={this.props.jukeboxId}
                                jukeboxOwner={this.props.jukebox.owner}
                                onSelected={this.handleSelected}
                            />
                        </StyledDialogContent>
                        <DialogActions>
                            {dialogActions.map(item => (
                                <Button key={item.label} disabled={item.disabled} onClick={item.onClick} color="primary">
                                    {item.label}
                                </Button>
                            ))}
                        </DialogActions>
                    </Dialog>
                </Container>
            </JukeboxThemeProvider>
        );
    }

    private handleSelected = (selectedTrackUri: string) => {
        window.setTimeout(() => {
            if (!selectedTrackUri)
                return;
            JukeboxClient.instance.addTrack(this.props.jukeboxId, selectedTrackUri);
            this.onHandleCloseDialog();
        }, 1);
    }

    private onHandleCloseDialog = () => {
        this.setState(() => ({
            isDialogOpen: false,
            selectedTrackUri: undefined,
        }));
    }

    private handleAddClick = () => {
        this.setState({ isDialogOpen: true });
    }
}

const Container = styled(FlexColumn)`
    -webkit-overflow-scrolling: touch;
    position: relative;
    text-align: center;
    flex-grow: 1;
    padding: 1vh;
    margin-bottom: 60px;
`;

const SubTitleContainer = styled("div")`
    display: flex;
    flex: 1 0 auto;
    padding-bottom: 1vh;
    padding-top: 1vh;
    margin: auto;
    justify-content: space-between;
`;

const StyledDialogContent: any = styled(DialogContent)`
    height: 45vh;
    display: flex;
    flex-direction: column;
    padding-top: 24px;

    .MuiPaper-root {
        background-color: transparent;
        background-image: none;
    }
`;

const subtitleStyle = {
    display: "flex",
    alignItems: "center"
}

const SubTitle = ({ children }) => (
    <SubTitleContainer>
        <Typography variant="subtitle1" color="primary" gutterBottom style={subtitleStyle}>
            {children}
        </Typography>
    </SubTitleContainer>
);