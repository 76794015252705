import { FC } from "react";
import { Theme, ThemeProvider } from "@mui/material";
import { JukeboxInfo } from "../types";

export interface JukeboxThemeProviderProps {
    children: React.ReactNode;
    jukebox: JukeboxInfo;
}

export const JukeboxThemeProvider: FC<JukeboxThemeProviderProps> = (props) => {
    return (
        <ThemeProvider theme={createJukeboxTheme(props.jukebox)}>
            {props.children}
        </ThemeProvider>
    )
}



function createJukeboxTheme(jukebox: JukeboxInfo) {
    return (baseTheme: Theme) => {
        const { brandColor } = jukebox;
        if (!brandColor) return baseTheme;

        baseTheme.palette.primary.main = brandColor;
        baseTheme.palette.secondary.main = brandColor;

        return baseTheme;
    }
}