import * as React from 'react'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface Props {
    onConfirm: Function;
    onCancel: Function;
    children: React.ReactNode;
}

export class ConfirmDialog extends React.PureComponent<Props> {

    render() {
        const { children } = this.props;

        return (
            <Dialog
                open
                onClose={this.handleConfirm}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
                <DialogContent>
                    <DialogContentText variant="body1" color="textPrimary">
                        {children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleConfirm} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    private handleConfirm = () => {
        this.props.onConfirm();
    }

    private handleCancel = () => {
        this.props.onCancel();
    }
}