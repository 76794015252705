import * as React from 'react'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { Container, ListContainer } from '../../components/Common';
import { Loading, Error } from './Components';
import { Typography } from '@mui/material';
import { ChoosePlaylistStepData, StepComponentProps } from './types';
import { Track } from '../../types';
import log from "../../log";

interface State {
    lastError?: any;
    playlists?: any;
    selectedPlaylistsUri?: string;
    loadingTracks?: boolean;
}

export class StepChoosePlaylist extends React.PureComponent<StepComponentProps<ChoosePlaylistStepData>, State> {

    constructor(props: StepComponentProps) {
        super(props);

        this.state = {};
        this.fetchUserPlaylists();
    }

    private fetchUserPlaylists() {
        return this.props.spotifyApi.getPlaylists()
            .then(response => {
                this.setState({ playlists: response.data});
            })
            .catch(err => {
                log.error("Failed to get user playlists", err);
                this.setState({...this.state, lastError: err});
            });
    }

    UNSAFE_componentWillMount() {
        this.props.onNext(() => this.loadPlaylistTracks());
    }

    render() {
        const { lastError, playlists, loadingTracks } = this.state;

        if (lastError) {
            const errorText = typeof(lastError) == 'object' ? lastError.message : lastError;
            return <Error>{errorText}</Error>;
        }

        const isLoadingPlaylists = playlists === undefined;
        if (isLoadingPlaylists) {
            return <Loading>Loading playlists...</Loading>;
        }

        if (loadingTracks) {
            return <Loading>Loading playlists...</Loading>;
        }

        const hasPlaylists = !isLoadingPlaylists && !!playlists.items && (playlists.items.length > 0);

        const items = !hasPlaylists ? undefined : playlists.items.map(item => {
            const imageUrl = !!item.images && (item.images.length > 0) ? item.images[item.images.length - 1].url : undefined;
            const uri = item.uri;
            const tracksUrl = item.tracks.href;
            const songs = item.tracks.total || 0;
            const name = item.name;

            return (
                <ListItem
                    selected={uri === this.state.selectedPlaylistsUri}
                    button
                    key={uri}
                    onClick={() => this.onPlaylistSelected(name, uri, tracksUrl)}
                >
                    <ListItemAvatar>
                        <Avatar alt={name} src={imageUrl} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={name}
                        secondary={'# of tracks: ' + songs}
                    />
                </ListItem>
            );
        })

        return (
            <Container className='setup-container'>
                <Typography gutterBottom>
                    Choose any of your playlists from your Spotify library. We'll use it to set up initial songs for the jukebox.
                </Typography>
                {hasPlaylists &&
                    <ListContainer>
                        <List className='playlists'>
                            {items}
                        </List>
                    </ListContainer>
                    }
                {!hasPlaylists && <Typography>No playlists found. Please add a playlist to your library in Spotify.</Typography>}
            </Container>
        )
    }

    private onPlaylistSelected = (name: string, uri: string, tracksUrl: string) => {
        this.setState({ selectedPlaylistsUri: uri });

        this.props.onData({
            playlistName: name,
            playlistsUri: uri,
            playlistsTracksUrl: tracksUrl
        });
    }

    private loadPlaylistTracks(): Promise<any> {
        const uri = this.props.stepDatas.playlist.playlistsTracksUrl;
        this.setState({ loadingTracks: true });

        return this.props.spotifyApi.getPlaylistTracks(uri)
            .then(items => {
                const tracks: Array<Track> = [];
                const trackIds = new Set<string>();

                items.forEach((item: any, index: number) => {
                    if (trackIds.has(item.track.uri))
                        return;

                    trackIds.add(item.track.uri);
                    tracks.push({
                        jukeboxIndex: index,
                        syncIndex: undefined,
                        spotifyId: item.track.uri,
                        lastVotesUp: 0,
                        lastVotesDown: 0,
                        artist: item.track.artists[0].name,
                        title: item.track.name,
                        lastPlayedTimestamp: 0,
                        lastVoteTimestamp: 0,
                        totalVotes: 0,
                        image: !!item.track.album.images && item.track.album.images.length > 0 ? item.track.album.images[0] : undefined,
                        voters: {
                            up: [],
                            down: []
                        },
                        linked_from_uri: item.track.linked_from ? item.track.linked_from.uri : undefined,
                        addedTimestamp: new Date().getTime(),
                    })
                })
                this.setState({ loadingTracks: false });
                this.props.onData({ tracks });
            })
            .catch(error => {
                log.error("Unable to load tracks", error);
                this.setState({
                    lastError: "Unable to load tracks, please try again",
                    loadingTracks: false
                })
            })
    }
}
