import * as React from 'react'
import { connect } from 'react-redux'
import Checkbox from '@mui/material/Checkbox';

import { Track, StoreType } from '../types'
import IconButton from '@mui/material/IconButton';

import { postVote } from '../actions';
import {
    ListTrackItem,
    TrackNumberContainer,
    TrackContentContainer,
    DownVotes,
    UpVotes,
    LastVotes,
    TrackArtist,
    UpDownContainer,
    VoteCountLabel
} from './PlaylistTrack.Components';
import Icon from '@mui/material/Icon';
import { voteGreenColor, voteRedColor, greyColor } from '../theme';

const voteUpButtonStyle = (disabled: boolean) => ({
    border: `1px solid ${voteGreenColor}`,
    color: disabled ? greyColor : voteGreenColor,
});

const voteDownButtonStyle = (disabled: boolean) => ({
    border: `1px solid ${voteRedColor}`,
    color: disabled ? greyColor : voteRedColor,
});

interface TrackProps {
    track: Track;
    showVotes: boolean;
    showLastVotes?: boolean;
    showPlaceholder?: boolean;
    selected?: boolean;
    onSelectionChange: (track: Track) => void;
    showVoteButtons?: boolean;
    disableVoteUpButtons?: boolean;
    disableVoteDownButtons?: boolean;
    jukeboxId: string;
    voterId?: string;
    minimal?: boolean;
    noTrackNumbers?: boolean;
    className?: string;
}


class PlaylistTrackImpl extends React.PureComponent<TrackProps> {

    render() {
        const { track, disableVoteUpButtons, disableVoteDownButtons, showLastVotes } = this.props;
        const voteDownDisabled = showLastVotes || disableVoteDownButtons || !!this.props.track.voters.down.find(v => v === this.props.voterId);
        const voteUpDisabled = showLastVotes || disableVoteUpButtons || !!this.props.track.voters.up.find(v => v === this.props.voterId);

        return (
            <ListTrackItem>
                {!!this.props.onSelectionChange &&
                    <Checkbox
                        color="primary"
                        checked={this.props.selected}
                        onChange={this.onSelectionChange}
                    />
                }
                {!this.props.noTrackNumbers &&
                    <TrackNumberContainer className='track-row-number'>
                        {track.jukeboxIndex + 1}
                    </TrackNumberContainer>
                }
                <TrackContentContainer className='track-row-info'>
                    {track.title}
                    <TrackArtist className="track-row-artist">{track.artist}</TrackArtist>
                </TrackContentContainer>
                <UpDownContainer center={this.props.minimal}>
                    {(this.props.showVotes || this.props.showLastVotes) &&
                        (this.props.showVoteButtons ?
                            <IconButton
                                onClick={() => postVote(this.props.jukeboxId, this.props.track.jukeboxIndex + 1, true)}
                                style={voteUpButtonStyle(voteUpDisabled)}
                                className="track-row-vote-up-button"
                                disabled={voteUpDisabled}
                            >
                                <Icon>thumb_up</Icon>
                                <VoteCountLabel up={true}>
                                    {this.props.showLastVotes ? track.lastVotesUp : track.voters.up.length}
                                </VoteCountLabel>
                            </IconButton>
                            : (this.props.showLastVotes ?
                                <LastVotes>{track.lastVotesUp}</LastVotes> :
                                <UpVotes>{track.voters.up.length}</UpVotes>)
                        )
                    }
                    {(this.props.showVotes || this.props.showLastVotes) &&
                        (this.props.showVoteButtons ?
                                <IconButton
                                    onClick={() => postVote(this.props.jukeboxId, this.props.track.jukeboxIndex + 1, false)}
                                    style={voteDownButtonStyle(voteDownDisabled)}
                                    className="track-row-vote-up-button"
                                    disabled={voteDownDisabled}
                                >
                                    <Icon>thumb_down</Icon>
                                    <VoteCountLabel up={false}>
                                        {this.props.showLastVotes ? track.lastVotesDown : track.voters.down.length}
                                    </VoteCountLabel>
                                </IconButton>
                            : (this.props.showLastVotes ?
                                <LastVotes>{track.lastVotesDown}</LastVotes> :
                                <DownVotes>{track.voters.down.length}</DownVotes>))}
                    </UpDownContainer>
                {this.props.showPlaceholder &&
                    <div />
                }
            </ListTrackItem>
        )
    }

    private onSelectionChange = () => {
        this.props.onSelectionChange(this.props.track);
    }
}

const mapStateToProps = (state: StoreType) => {
    return {
        voterId: state.account.voterId.data
    }
}

const ConnectedPlaylistTrack = connect<{ voterId: string}, {}, TrackProps>(mapStateToProps)(PlaylistTrackImpl);

export class PlaylistTrack extends React.Component<TrackProps> {

    render() {
        return <ConnectedPlaylistTrack {...this.props} />;
    }

}