import { JukeboxInfo, Tracks, Track } from "./types"

const DEFAULT_MAX_VOTES_PER_USER = 5;

export const getMaxVotesPerUser = (jb: JukeboxInfo) => {
    return jb.maxVotesPerUser || DEFAULT_MAX_VOTES_PER_USER;
}

export const getUserVoteCount = (user: string, up: boolean, tracks: Tracks): number => {
    return tracks.reduce<number>((val: number, currentTrack: Track) => {
        const arr = up ? currentTrack.voters.up : currentTrack.voters.down;
        return val + (arr.indexOf(user) >= 0 ? 1 : 0);
    }, 0);
}