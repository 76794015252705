import * as React from 'react';
import styled from '@emotion/styled';
import { primaryColor } from '../theme';
import { FlexColumn } from './Common';
import { css } from '@emotion/css';

interface TabsProps {
    selected: number;
    labels: React.ReactChild[];
    onChange?: (index: number) => void;
    autoScroll?: boolean;
    children?: React.ReactNode;
    color?: string;
}

interface State {
    selectedItemRect?: ClientRect | DOMRect;
}

export class Tabs extends React.Component<TabsProps, State> {

    state: State = {};

    render() {


        const { children, labels, color } = this.props;
        const { selectedItemRect } = this.state;

        const bgColorClass = !color ? '' : css`
            background-color: ${color} !important;
        `;

        return (
            <Container className="tabs">
                <LabelContainer className="labels" ref={this.setLabelsRef}>
                    {labels}
                </LabelContainer>
                <SelectionArea>
                    <SelectionLine className={bgColorClass} />
                    <SelectionHighlight style={{ left: selectedItemRect && selectedItemRect.left, width: selectedItemRect && selectedItemRect.width }} className={bgColorClass} />
                </SelectionArea>
                <ContentContainer className="content">
                    {children}
                </ContentContainer>
            </Container>
        );
    }

    componentDidUpdate() {
        this.setSelectedTabPos();
    }

    private setSelectedTabPos() {
        if (!this.labelsContainer) {
            return;
        }

        const selected = this.props.selected || 0;
        if (selected >= this.labelsContainer.childElementCount) {
            return;
        }

        const containerRect = this.labelsContainer.getBoundingClientRect();
        const childNode = this.labelsContainer.children.item(selected);
        const selectedItemRect = childNode.getBoundingClientRect();

        const currentRect = this.state.selectedItemRect;
        const itemRect = DOMRect.fromRect({
            y: selectedItemRect.top - containerRect.top,
            x: selectedItemRect.left - containerRect.left,
            width: selectedItemRect.width,
            height: selectedItemRect.height
        });
        if (currentRect && currentRect.left === itemRect.left && currentRect.width === itemRect.width)
            return;

        this.setState({ selectedItemRect: itemRect });
    }

    private labelsContainer: HTMLDivElement;
    private setLabelsRef = (container: HTMLDivElement) => {
        this.labelsContainer = container;
        this.setSelectedTabPos();
    }

}

export const EmptyTabHeaderItem = styled("button")`
    flex: 1 1 auto;
    height: 32px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
`;

const Container = styled(FlexColumn)`
    flex: 0 0 auto;
    flex-direction: column;
`;

const ContentContainer = styled('div')`
    flex: 1 1 auto;
`;

const LabelContainer = styled('div')`
    display: flex;
    flex: 0 0 auto;
`;

const SelectionArea = styled("div")`
    position: relative;
    height: 4px;
    margin-top: -4px;
    z-index: -1;
`;

const SelectionLine = styled("div")`
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    position: absolute;
    background-color: ${primaryColor};
    opacity: 0.2;
`;

const SelectionHighlight = styled("div")`
    height: 4px;
    bottom: 0;
    position: absolute;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: ${primaryColor};
`;