import * as React from 'react';

import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import { PlaybackInfo, JukeboxStatus, StoreType, JukeboxInfo } from '../types';
import { PLAYBACK_VOTE_THRESHOLD_MS, JukeboxController } from '../JukeboxController';
import styled from '@emotion/styled';
import { FlexColumn } from './Common';
import { css } from '@emotion/css';

interface TopTrackInfoWithVoteTimerProps {
    playbackInfo?: PlaybackInfo;
    jukebox?: JukeboxInfo
    jukeboxController?: JukeboxController;
}

interface TopTrackInfoWithVoteTimerState {
    readonly timeToVoteInSec: number;
}

class TopTrackInfoWithVoteTimerImpl extends React.PureComponent<TopTrackInfoWithVoteTimerProps, TopTrackInfoWithVoteTimerState> {

    private _durationTimer: any = 0;
    private _lastSyncTime: Date;

    constructor(props: TopTrackInfoWithVoteTimerProps) {
        super(props);

        this.state = {
            timeToVoteInSec: this.getTimeToVoteFromPlaybackInfo()
        }

        this._lastSyncTime = new Date();
    }

    componentDidMount() {
        this._durationTimer = setInterval(this.onTimer, 1000);
    }

    componentWillUnmount() {
        clearInterval(this._durationTimer);
    }

    UNSAFE_componentWillUpdate(nextProps: TopTrackInfoWithVoteTimerProps, nextState: TopTrackInfoWithVoteTimerState) {
        if (nextProps.playbackInfo !== this.props.playbackInfo) {
            this._lastSyncTime = new Date();
        }
    }

    render() {
        const { jukebox, jukeboxController } = this.props;
        if (!jukeboxController) {
            return null;
        }

        const jukeboxStatus = jukebox && jukebox.status;
        const upcomingTrackSet = jukebox && !!jukebox.upcomingTrack;

        let minutes: any = !upcomingTrackSet && this.state.timeToVoteInSec > 0 ? Math.floor(this.state.timeToVoteInSec / 60) : undefined;
        let seconds: any = !upcomingTrackSet && this.state.timeToVoteInSec > 0 ? this.state.timeToVoteInSec % 60 : undefined;

        if (minutes === 0)
            minutes = '0';

        if (seconds !== undefined && (seconds < 10))
            seconds = '0' + seconds;

        const infoText = (minutes === undefined || seconds === undefined) ? '' :
            `Time remaining to vote for next track ${minutes}:${seconds}`;

        const text = jukeboxStatus === JukeboxStatus.Running ? infoText : "";
        return (
            <Typography variant="subtitle1" color="primary" style={{ marginLeft: "auto"}}>
                {text}
            </Typography>
        );
    }

    private getTimeToVoteFromPlaybackInfo() {
        if (this.props.playbackInfo) {
            return Math.round((this.props.playbackInfo.durationMs - this.props.playbackInfo.progressMs - PLAYBACK_VOTE_THRESHOLD_MS) / 1000.0)
        }

        return 0;
    }

    private onTimer = () => {
        const { jukebox } = this.props;

        const jukeboxStatus = jukebox && jukebox.status;

        if (!this.props.playbackInfo || !this.props.playbackInfo.playing || jukeboxStatus !== JukeboxStatus.Running) {
            return;
        }

        const secsSinceSyncTime = Math.round((new Date().getTime() - this._lastSyncTime.getTime()) / 1000.0);

        this.setState({
            timeToVoteInSec: this.getTimeToVoteFromPlaybackInfo() - secsSinceSyncTime
        })
    }
}
export const TopTrackInfoWithVoteTimer = connect((state: StoreType): Partial<TopTrackInfoWithVoteTimerProps> => ({
    jukeboxController: state.app.jukeboxController,
    playbackInfo: state.spotifyState.playbackInfo,
    jukebox: state.jukebox,
}))(TopTrackInfoWithVoteTimerImpl);

export const HeaderLogoContainer = styled("div")`
    flex: 1;
    display: flex;
`;

export const HeaderContainer = styled(FlexColumn)`
    flex-shrink: 0;
`;

export const HeaderImg = styled("img")`
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 2px;
    height: 48px;
    padding: 4px;
`;

export const LogoText = styled("span")`
    position: relative;
    font-size: 30px;
    margin-top: 10px;
    font-weight: 300;
    letter-spacing: -2px;
`;

const logoClass = css`
    position: absolute;
    bottom: 0px;
    right: -10px;
    font-weight: 600 !important;
    font-size: 12px !important;
    letter-spacing: 0px !important;
`;

const LogoAuthor: React.FC = () => {
    return (
        <Typography component="span" color="primary" className={logoClass}>by @mabel</Typography>
    )
}

export interface JukeboxLogoProps {
    jukebox: JukeboxInfo;
}

export const JukeboxLogo: React.FC<JukeboxLogoProps> = (props) => {
    const logoUrl = props.jukebox.logoUrl || "https://www.twilio.com/docs/static/company/img/logos/red/twilio-logo-red.4b9d32d19.svg";

    return (
        <HeaderLogoContainer className="logo-container">
            <HeaderImg src={logoUrl} />
            <LogoText className="header-logo-text">
                jukebob
                <LogoAuthor />
            </LogoText>
        </HeaderLogoContainer>
    )
}