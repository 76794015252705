import * as React from "react";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import { Redirect } from "react-router";

import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

import { StoreType, JukeboxInfo, Tracks } from "../types";

import { SyncController } from "../SyncController";
import { JukeboxClient } from "../JukeboxClient";

import { PlaylistTracks } from "../components/PlaylistTracks";

import { asYouType } from "libphonenumber-js";

import { Container } from "../components/Common";
import styled from "@emotion/styled";
import { Typography, Tooltip } from "@mui/material";
import log from "../log";
import { SpotifyRequestHandler } from "../SpotifyRequestHandler";

interface Props {
    readonly tracks: Tracks;
    readonly jukebox: JukeboxInfo;
    readonly history?: any;
    readonly match?: {
        params: {
            jukeboxId: string;
        };
    };
}

interface State {
    redirectToPage?: string;
    deleteConfirmNeeded: boolean;
    loading: boolean;
    allSelected: boolean;
    isMenuOpen: boolean;
    anchorEl: any;
    error: string;
    spotifyApi: SpotifyRequestHandler;
}

const AdditionalMenu = (props: {
    onClose: any;
    anchorEl: any;
    allSelected: boolean;
    hasSelection: boolean;
    onSelected: (item: string) => void;
}) => (
    <Menu
        anchorEl={props.anchorEl}
        anchorOrigin={{
            vertical: "top",
            horizontal: "right"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right"
        }}
        open={!!props.anchorEl}
        onClose={props.onClose}
    >
        {!props.allSelected && <MenuItem onClick={() => props.onSelected("selectAll")}>Select all</MenuItem>}
        {props.allSelected && <MenuItem onClick={() => props.onSelected("unselectAll")}>Unselect all</MenuItem>}
        <MenuItem disabled={!props.hasSelection} onClick={() => props.onSelected("addSelected")}>
            Add selected to Spotify playlist
        </MenuItem>
        <MenuItem disabled={!props.hasSelection} onClick={() => props.onSelected("replaceSelected")}>
            Replace selected in Spotify playlist
        </MenuItem>
        <MenuItem disabled={!props.hasSelection} onClick={() => props.onSelected("resetSelected")}>
            Reset selected
        </MenuItem>
        <MenuItem disabled={!props.hasSelection} onClick={() => props.onSelected("deleteSelected")}>
            Delete selected
        </MenuItem>
        <MenuItem onClick={() => props.onSelected("setup")}>Re-initialize from playlist</MenuItem>
        <MenuItem onClick={() => props.onSelected("deleteJukebox")}>Delete jukebox</MenuItem>
    </Menu>
);

class JukeboxAdminView extends React.PureComponent<Props, State> {
    private jukeboxId: string;

    private syncController: SyncController;

    private _playlistTracks: PlaylistTracks;

    constructor(props: Props) {
        super(props);
        this.state = {
            deleteConfirmNeeded: false,
            loading: true,
            allSelected: false,
            anchorEl: null,
            isMenuOpen: false,
            error: undefined,
            spotifyApi: undefined
        };

        this.jukeboxId = props.match.params.jukeboxId;

        SyncController.get(this.jukeboxId)
            .then((controller) => {
                this.syncController = controller;
                this.syncController.loadTracks();
            })
            .catch((e) => {
                this.setState({ error: "Unable to access jukebox" });
                log.error("Unable to access jukebox", e);
            })
            .then(() => {
                this.setState((state) => ({ ...state, loading: false }));
            });
    }

    componentDidMount() {
        SpotifyRequestHandler.create().then((spotifyApi) => {
            this.setState({
                spotifyApi
            });
        });
    }

    componentWillUnmount() {
        if (this.syncController) {
            this.syncController.cleanup();
        }
    }

    public render() {
        const { redirectToPage, loading, error } = this.state;

        if (redirectToPage) {
            return <Redirect to={this.state.redirectToPage} />;
        }

        if (loading) {
            return (
                <Container>
                    <CircularProgress />
                </Container>
            );
        }

        if (error) {
            return (
                <Container>
                    <Typography variant="body1" color="error">
                        Error occurred: {error}
                    </Typography>
                </Container>
            );
        }

        return (
            <AdminContainer>
                <Typography variant="h3" color="primary">
                    {this.props.jukebox.playlistName}{" "}
                </Typography>
                <Typography variant="h3" gutterBottom>
                    Administration
                </Typography>
                <Typography variant="h4" gutterBottom>
                    Phone number:{" "}
                    {(!!this.props.jukebox.phoneNumber && new asYouType().input(this.props.jukebox.phoneNumber)) ||
                        "Not assigned"}
                </Typography>
                <Typography variant="body1">
                    Here you can administer jukebox. You can re-initialize jukebox from a playlist by keeping the phone
                    number, delete tracks or reset votes.
                </Typography>
                <div className="jukebox-info">
                    <Tooltip title="Start jukebox" aria-label="Start Jukebox">
                        <Fab variant="extended" color="primary" onClick={this.onStartClick}>
                            <Icon>list</Icon>
                            Open Jukebox
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Edit" aria-label="Edit">
                        <IconButton
                            aria-owns={this.state.anchorEl ? "menu-appbar" : undefined}
                            aria-haspopup="true"
                            onClick={this.handleMenu}
                            color="primary"
                        >
                            <Icon>edit</Icon>
                        </IconButton>
                    </Tooltip>
                    <AdditionalMenu
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleMenuClose}
                        onSelected={this.onMenuSelection}
                        allSelected={this.state.allSelected}
                        hasSelection={!!this._playlistTracks && this._playlistTracks.state.selected.size > 0}
                    />
                </div>
                <br />
                <br />
                {this.props.tracks.length ? (
                    <TracksContainer>
                        <PlaylistTracks
                            tracks={this.props.tracks}
                            jukebox={this.props.jukebox}
                            selectable={true}
                            onSelectionChange={this.onTrackSelectionChanged}
                            jukeboxId={undefined}
                            ref={this.setPlaylistTracksRef}
                            noTrackNumbers
                        />
                    </TracksContainer>
                ) : (
                    <Typography>No tracks found</Typography>
                )}
                <Dialog
                    open={this.state.deleteConfirmNeeded}
                    onClose={this.onHandleCloseDialog}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete? This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {this.deleteActions.map((item) => (
                            <Button key={item.label} onClick={item.onClick} color="primary">
                                {item.label}
                            </Button>
                        ))}
                    </DialogActions>
                </Dialog>
            </AdminContainer>
        );
    }
    private handleMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    private handleMenuClose = () => {
        this.setState((state) => ({ ...state, anchorEl: null }));
    };

    private setPlaylistTracksRef = (item: PlaylistTracks) => {
        this._playlistTracks = item;
    };

    private onMenuSelection = (action: string) => {
        if (action === "deleteJukebox") {
            this.onDeleteClick();
        } else if (action === "deleteSelected") {
            this.deleteSelectedTracks();
        } else if (action === "resetSelected") {
            this.resetSelectedTracks();
        } else if (action === "selectAll") {
            this.props.tracks.forEach((track) => {
                this._playlistTracks.state.selected.add(track.syncIndex);
            });
            this._playlistTracks.forceUpdate();

            this.setState({ ...this.state, allSelected: true });
        } else if (action === "unselectAll") {
            this.props.tracks.forEach((track) => {
                this._playlistTracks.state.selected.delete(track.syncIndex);
            });
            this._playlistTracks.forceUpdate();
            this.setState({ ...this.state, allSelected: false });
        } else if (action === "setup") {
            this.props.history.push(`/setup/${this.jukeboxId}`);
        } else if (action === "addSelected") {
            this.addSelectedTracksToPlaylist();
        } else if (action === "replaceSelected") {
            this.replaceSelectedTracksInPlaylist();
        }

        this.handleMenuClose();
    };

    private onTrackSelectionChanged = () => {
        this.forceUpdate();
    };

    private onStartClick = () => {
        this.props.history.push(`/${this.jukeboxId}`);
    };

    private onDeleteClick = () => {
        this.setState({ ...this.state, deleteConfirmNeeded: true });
    };

    private onDeleteConfirmedClick = () => {
        JukeboxClient.instance.deleteJukebox(this.jukeboxId).then(() => {
            this.props.history.push("/jukeboxes");
        });
        // todo, dispatch error
        // .catch(() => {
        // })
    };

    private deleteSelectedTracks = () => {
        // this.setState({...this.state,
        //     loading: true
        // });

        this.syncController.deleteTracksBySyncIndexes(this._playlistTracks.state.selected).then(() => {
            this._playlistTracks.state.selected.clear();
            this.setState({ ...this.state, loading: false });
        });

        // TODO
        // JukeboxClient.instance.deleteJukeboxTracks(this.jukeboxId, Array.from(this.state.selected.values()))
    };

    private resetSelectedTracks = () => {
        // unselect tracks with 0 votes
        this.props.tracks.forEach((t) => {
            if (t.voters.up.length === 0 && t.voters.down.length === 0) {
                this._playlistTracks.state.selected.delete(t.syncIndex);
            }
        });

        this.syncController.resetVotesBySyncIndexes(this._playlistTracks.state.selected).then(() => {
            this._playlistTracks.state.selected.clear();
        });

        this._playlistTracks.forceUpdate();
    };

    private getSelectedTrackUris(): Array<string> {
        const selectedSyncIndexes = Array.from(this._playlistTracks.state.selected).map((syncIndex) => {
            const existingTrack = this.props.tracks.find((t) => t.syncIndex === syncIndex);
            return existingTrack.spotifyId;
        });
        return selectedSyncIndexes;
    }

    private addSelectedTracksToPlaylist = () => {
        if (!this.props.jukebox.spotifyTracksUrl) {
            console.error("No spotifyTracksUrl prop, please recreate your playlist");
            return;
        }

        const selectedTracks = this.getSelectedTrackUris();
        if (!selectedTracks) {
            window.alert("No selected tracks");
            return;
        }

        this.state.spotifyApi
            .addPlaylistTracks(this.props.jukebox.spotifyTracksUrl, selectedTracks)
            .then(() => {
                window.alert("Done");
            })
            .catch((e) => {
                console.error(e);
                window.alert("Something did not work");
            });
    };

    private replaceSelectedTracksInPlaylist = () => {
        if (!this.props.jukebox.spotifyTracksUrl) {
            console.error("No spotifyTracksUrl prop, please recreate your playlist");
            return;
        }

        const selectedTracks = this.getSelectedTrackUris();

        this.state.spotifyApi
            .replacePlaylistTracks(this.props.jukebox.spotifyTracksUrl, selectedTracks)
            .then(() => {
                window.alert("Done");
            })
            .catch((e) => {
                console.error(e);
                window.alert("Something did not work");
            });
    };

    private onHandleCloseDialog = () => {
        this.setState({ ...this.state, deleteConfirmNeeded: false });
    };

    private deleteActions = [
        { label: "Cancel", onClick: this.onHandleCloseDialog },
        { label: "Delete", onClick: this.onDeleteConfirmedClick }
    ];
}

const mapStateToProps = (state: StoreType): Props => {
    return {
        jukebox: state.jukebox,
        tracks: state.tracks.tracks
    };
};

export default withRouter(connect(mapStateToProps)(JukeboxAdminView));

const TracksContainer = styled("div")`
    align-items: initial;
    overflow-y: scroll;
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
    width: 80%;
`;

const AdminContainer = styled(Container)`
    overflow: auto;
`;
