import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel, { StepLabelProps } from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Container } from "../../components/Common";

import { StepComponentContainer, StepContentContainer, StepContainer, Loading } from "./Components";
import { StepChoosePlaylist } from "./StepChoosePlaylist";
import { StepSaveJukebox } from "./StepSaveJukebox";
import { AccountState, StoreType } from "../../types";
import { StepInfo, StepDatas, SaveJukeboxStepData } from "./types";
import { SpotifyRequestHandler } from "../../SpotifyRequestHandler";
import { StepsDone } from "./StepsDone";
// import { StepAssignPhoneNumber } from "./StepAssignPhoneNumber";
import { JukeboxClient } from "../../JukeboxClient";
import { SyncController } from "../../SyncController";
import { JukeboxMeta } from "../../../server/src/types/api";
import log from "../../log";

interface Props {
    account: AccountState;
    match?: {
        params?: {
            jukeboxId?: string;
        };
    };
}

interface State {
    activeStep: number;
    beforeNext: () => Promise<any>;
    beforeNextPending?: boolean;
    stepDatas: StepDatas;
    spotifyApi: SpotifyRequestHandler;
    syncController: SyncController;
}

const steps: Array<StepInfo> = [
    {
        label: "Choose playlist",
        component: StepChoosePlaylist,
        dataKey: "playlist"
    },
    {
        label: "Enter details",
        component: StepSaveJukebox,
        dataKey: "jukebox"
    },
    // {
    //     label: "Assign phone number",
    //     component: StepAssignPhoneNumber,
    //     dataKey: "phoneNumber",
    //     optional: true
    // },
    {
        label: "Done",
        component: StepsDone,
        dataKey: "jukebox"
    }
];

class SetupViewImpl extends React.PureComponent<Props, State> {
    state: State = {
        activeStep: 0,
        stepDatas: {},
        spotifyApi: null,
        syncController: null,
        beforeNext: null
    };

    componentDidMount() {
        SpotifyRequestHandler.create().then((spotifyApi) => {
            this.setState({
                spotifyApi
            });

            if (this.props.match && this.props.match.params.jukeboxId) {
                this.loadJukeboxInfo(this.props.match.params.jukeboxId);
            }
        });
    }

    componentWillUnmount() {
        const { syncController } = this.state;
        if (syncController) {
            syncController.cleanup();
        }
    }

    render() {
        const { activeStep, stepDatas, spotifyApi, syncController, beforeNextPending } = this.state;
        if (!spotifyApi || (this.getJukeboxId() && !stepDatas.jukebox)) {
            return <Loading>Initializing...</Loading>;
        }

        const step = activeStep < steps.length ? steps[activeStep] : null;
        const StepComponent = (step && step.component) || null;
        const stepData = step && stepDatas[step.dataKey];

        const nextButtonCaption =
            activeStep === steps.length - 1 ? "Finish" : stepData || !step.optional ? "Next" : "Skip";

        return (
            <Container className="setup-container">
                <Typography variant="h3" gutterBottom>
                    Jukebox Setup
                </Typography>
                <Stepper activeStep={activeStep}>
                    {steps.map((step) => {
                        const props = {};
                        const labelProps: Partial<StepLabelProps> = {};
                        // if (step.optional) {
                        //     labelProps.optional = <Typography variant="caption">Optional</Typography>;
                        // }
                        return (
                            <Step key={step.label} {...props}>
                                <StepLabel {...labelProps}>{step.label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <StepContainer className="step-content">
                    <StepContentContainer className="step">
                        <div className="step-actions">
                            <Button disabled={activeStep === 0} onClick={this.handleBack}>
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleNext}
                                disabled={
                                    (!stepData && !step.optional) ||
                                    step.automated ||
                                    activeStep === steps.length - 1 ||
                                    beforeNextPending
                                }
                            >
                                {nextButtonCaption}
                            </Button>
                        </div>
                        <StepComponentContainer className="step-component-container">
                            <StepComponent
                                onData={(data) => this.handleStepData(step.dataKey, data)}
                                stepDatas={stepDatas}
                                spotifyApi={spotifyApi}
                                syncController={syncController}
                                onClear={() => this.handleStepData(step.dataKey, null)}
                                onNext={this.setBeforeNextPromise}
                                onSave={this.handleSave}
                            />
                        </StepComponentContainer>
                    </StepContentContainer>
                </StepContainer>
            </Container>
        );
    }

    private loadJukeboxInfo(jukeboxId: string) {
        this.getSyncController(jukeboxId)
            .then((syncController) => syncController.getJukeboxInfo())
            .then((jb) => {
                this.handleStepData("jukebox", {
                    name: jb.playlistName,
                    id: jukeboxId,
                    shortUrl: jb.shortUrl,
                    brandColor: jb.brandColor || '',
                    logoUrl: jb.logoUrl || ''
                } as SaveJukeboxStepData);
            })
            .catch((err) => log.error("Failed to load jukebox data", err));
    }

    private getCurrentStep() {
        const { activeStep } = this.state;

        return activeStep < steps.length ? steps[activeStep] : null;
    }

    private setBeforeNextPromise = (cb: () => Promise<any>) => {
        this.setState(() => ({ beforeNext: cb }));
    };

    private handleStepData = (dataKey: keyof StepDatas, data: any) => {
        this.setState((state) => {
            const prevStepData = state.stepDatas[dataKey] || {};

            return {
                stepDatas: {
                    ...state.stepDatas,
                    [dataKey]: data !== null ? { ...prevStepData, ...data } : null
                }
            };
        });

        const step = this.getCurrentStep();
        if (step.automated) {
            this.handleNext();
        }
    };

    private handleNext = () => {
        const { beforeNext } = this.state;

        const prePromise = beforeNext ? beforeNext() : Promise.resolve();

        this.setState({ beforeNextPending: !!prePromise });

        return prePromise
            .then(() => {
                this.setState((state) => ({
                    activeStep: state.activeStep + 1,
                    beforeNext: undefined,
                    beforeNextPending: false
                }));
            })
            .catch((e) => {
                this.setState({ beforeNextPending: false });
                log.error("Unable to proceed to next step", e);
            });
    };

    private handleBack = () => {
        this.setState((state) => ({
            activeStep: state.activeStep - 1,
            beforeNext: undefined
        }));
    };

    private handleSave = () => {
        return this.createOrUpdateJukebox().then((jbMeta) => this.saveToSync(jbMeta));
    };

    private getJukeboxId() {
        return this.state.stepDatas.jukebox && this.state.stepDatas.jukebox.id;
    }

    private createOrUpdateJukebox(): Promise<JukeboxMeta> {
        const jukeboxId = this.getJukeboxId();

        // create new jukebox
        if (!jukeboxId) {
            return JukeboxClient.instance
                .createJukebox({
                    friendlyName: this.state.stepDatas.jukebox.name
                })
                .then((jb) => {
                    this.handleStepData("jukebox", { id: jb._id });
                    return jb;
                });
        }

        const cleanupSync = !this.state.syncController
            ? Promise.resolve()
            : this.state.syncController.cleanup().then(() => this.setState({ syncController: null }));

        return cleanupSync.then(() => {
            return JukeboxClient.instance.updateJukebox({
                _id: jukeboxId,
                friendlyName: this.state.stepDatas.jukebox.name
            });
        });
    }

    private getSyncController(jukeboxId: string): Promise<SyncController> {
        const getSyncControllerPromise = this.state.syncController
            ? Promise.resolve(this.state.syncController)
            : SyncController.get(jukeboxId);

        return getSyncControllerPromise;
    }

    private saveToSync = (jukeboxMeta: JukeboxMeta) => {
        return this.getSyncController(jukeboxMeta._id).then((syncController) => {
            if (syncController !== this.state.syncController) {
                this.setState({ syncController });
            }

            return syncController.setupJukebox(
                this.state.stepDatas.jukebox,
                this.props.account.user.data._id,
                this.state.stepDatas.playlist.tracks,
                jukeboxMeta.shortUrl,
                jukeboxMeta.phoneNumber,
                this.state.stepDatas.playlist.tracks.length - 1,
                this.state.stepDatas.playlist.playlistsTracksUrl,
            );
        });
    };
}

const mapStateToProps = (state: StoreType): Props => {
    return {
        account: state.account
    };
};
export const SetupView = withRouter(connect(mapStateToProps)(SetupViewImpl));
