import * as React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { voteGreenColor, voteRedColor } from "../theme";

export interface ListTrackItemProps {
}
export const ListTrackItem = styled("li")<ListTrackItemProps>`
    align-items: center;
    flex: 0 0 auto;
    min-height: 8.6vh;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    /* padding-bottom: 1vh; */
    /* padding-top: 1vh; */
    border-bottom: 0.1vh solid #444444;
    -webkit-transition: background-color .4s;
    transition: background-color .4s;
    padding-left: 3vw;
    padding-right: 3vw;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.8);
        -webkit-transition: background-color .1s;
        transition: background-color .1s;
    }
`;


export interface ListItemInfoProps {}

export const ListItemInfo = styled("li")<ListItemInfoProps>`
    display: flex;
    flex: 1 0 auto;
    /* height: 2.9vh; */

    padding-left: 1vw;
    padding-right: 3vw;

    justify-content: space-between;
    & > div {
        text-align: left;
        flex-grow: 0 1 auto;
    }
`;

export const TrackNumberContainer = styled("div")`
    text-align: right;
    font-size: 4vh;
    font-weight: 300;
    margin: auto;
    flex: 0 0 6vw;
`;

export const TrackContentContainer = styled("div")`
    flex-grow: 1;
    margin-left: 2vw;
    margin-top: auto;
    margin-bottom: auto;

    font-size: 3vh;
    font-weight: bold;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const TrackArtist = styled("div")`
    font-size: 2.5vh;
    font-weight: normal;
    color: #cccccc;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const VotesContainer = styled("div")`
    font-size: 3vh;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
`;

export const UpVotes = styled(VotesContainer)`
    color: ${voteGreenColor};
`;

export const DownVotes = styled(VotesContainer)`
    color: ${voteRedColor};
`;

export const LastVotes = styled(VotesContainer)`
    color: #cccccc;
`;

const StyledIcon = styled("i")`
    font-size: 3vh;
    font-weight: bold;
`

export const StyledUpIcon = styled(StyledIcon)`
    color: ${voteGreenColor};
`;

export const StyledDownIcon = styled(StyledIcon)`
    color: ${voteRedColor};
`;

interface UpDownContainerRootProps {
    center?: boolean;
}
const UpDownContainerRoot = styled("div")<UpDownContainerRootProps>`
    flex: 0 0 16vh;
    display: flex;

    & > * {
        text-align: ${props => props.center ? "center" : "right"};
        width: 50%;
        margin: 2px;
    }
`;

export const UpDownContainer = (props: { center?: boolean, children: any }) => (
    <UpDownContainerRoot center={props.center}>
        <div>
            {props.children[0]}
        </div>
        <div>
            {props.children[1]}
        </div>
    </UpDownContainerRoot>
);

const VoteCountLabelContainer = styled("div")<{ up: boolean}>`
    position: absolute;
    right: 0;
    bottom: -1vh;
    width: 2.6vh;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 1000px;
    color: ${props => props.up ? voteGreenColor : voteRedColor} !important;
`;
export const VoteCountLabel: React.FunctionComponent<{up: boolean, children: React.ReactNode}> = (props) => (
    <VoteCountLabelContainer up={props.up}>
        <Typography color="inherit">
            {props.children}
        </Typography>
    </VoteCountLabelContainer>
);