import * as React from 'react'
import { withRouter } from 'react-router'
import styled from '@emotion/styled';
import { Typography, Fab, Icon } from '@mui/material';

import { StepComponentProps } from './types';
import { FlexColumn } from '../../components/Common';



interface Props extends StepComponentProps {
    history?: any;
}

class StepsDoneImpl extends React.PureComponent<Props> {

    render() {
        return (
        <Container className="details">
            <Typography variant="h4" gutterBottom>
                All steps completed!
            </Typography>
            <Fab
                variant="extended"
                onClick={() => this.props.history.push(`/${this.props.stepDatas.jukebox.id}`)}
                color="primary"
            >
                <Icon>playlist_play</Icon>
                Go to jukebox
            </Fab>
        </Container>
        );
    }
}
export const StepsDone = withRouter(StepsDoneImpl);

const Container = styled(FlexColumn)`
    padding: 2vh;
    margin: auto;
`;