import styled from "@emotion/styled";
import { FlexColumn } from "./Common";


export const LeftPaneContainer = styled(FlexColumn)`
    background-image: linear-gradient(to bottom, rgba(64,64,64,0.5), rgba(24,24,24,0.1));
    flex: 0 0 30%;
    padding: 1vh;
`;

export const LeftPaneContent = styled(FlexColumn)`
    justify-content: space-evenly;
    flex: 1;
`;