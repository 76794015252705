import * as React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import styled from '@emotion/styled';
import { StoreType } from '../types';
import { JukeboxController } from '../JukeboxController';
import log from "../log";

interface Props {
    jukeboxController?: JukeboxController;
}

interface State {
    anchorEl: any;
    value: number;
    loadedVolume?: boolean;
}

class VolumeControlMenu extends React.PureComponent<Props, State> {

    state: State = {
        anchorEl: undefined,
        value: 0
    };

    componentDidMount() {
        this.loadVolume(this.props);
    }

    componentDidUpdate(prevProps: Props) {
        this.loadVolume(prevProps);
    }

    private loadVolume(prevProps: Props) {
        const { jukeboxController } = this.props;

        let loadedVolume = this.state.loadedVolume;
        if (!jukeboxController || jukeboxController !== prevProps.jukeboxController) {
            loadedVolume = false;
        }

        if (jukeboxController && !loadedVolume) {
            jukeboxController.spotifyApi.getVolume()
                .then(volume => {
                    this.setState({
                        value: Math.round(volume * 100),
                        loadedVolume: true
                    });
                })
                .catch((error) => {
                    log.error("Failed to fetch volume", error);
                });
        }
    }

    render() {
        const { jukeboxController } = this.props;
        if (!jukeboxController) {
            return null;
        }

        const { anchorEl, value } = this.state;

        return (
            <>
                <Tooltip title="Volume" aria-label="Volume">
                    <IconButton
                        aria-owns={!!anchorEl ? 'menu-appbar' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                        color="primary"
                    >
                        <Icon>{value > 0 ? (value > 50 ? "volume_up" : "volume_down") : "volume_off"}</Icon>
                    </IconButton>
                </Tooltip>
                <Menu
                    color="primary"
                    anchorEl={anchorEl}
                    open={!!anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        horizontal: "right",
                        vertical: "top"
                    }}
                >
                    <SliderContainer>
                        <StyledSlider
                            value={value}
                            onChange={this.handleChange}
                            orientation="vertical"
                        />
                    </SliderContainer>
                </Menu>
            </>
        )
    }

    private handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    private handleChange = async (event, value) => {
        this.setState({ value });

        this.setVolume(value);
    };

    private _settingVolume = false;
    private _pendingVolume: number = null;
    private async setVolume(volume: number) {
        if (this._settingVolume) {
            this._pendingVolume = volume;
            return;
        }

        if (volume >= 1) {
            volume = Math.round(volume) / 100;
        }

        const { jukeboxController } = this.props;
        if (jukeboxController) {
            this._settingVolume = true;
            log.trace("setting volume", volume);
            return jukeboxController.spotifyApi.setVolume(volume)
                .catch((error) => {
                    log.error("Unable to set volume", error);
                })
                .then(() => {
                    this._settingVolume = false;

                    if (this._pendingVolume !== null) {
                        volume = this._pendingVolume;
                        this._pendingVolume = null;
                        return this.setVolume(volume);
                    }
                });
        }
    }

    private handleClose = () => {
        this.setState({ anchorEl: null });
    };

}

const SliderContainer = styled("div")`
    display: flex;
    height: 200px;
    width: 30px;
    overflow: hidden;
    padding-bottom: 26px;
    padding-top: 26px;
    padding-left: 10px;
    padding-right: 10px;
`;


const StyledSlider = Slider;

const mapStateToProps = (state: StoreType): Partial<Props> => {
    return {
        jukeboxController: state.app.jukeboxController
    }
}

const VolumeControl = connect(mapStateToProps)(withRouter(VolumeControlMenu));
export { VolumeControl };