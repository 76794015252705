import { css } from '@emotion/css';
import { createTheme } from '@mui/material/styles';

export const primaryColor = '#f22f46';
export const greyColor = '#cccccc';
export const voteRedColor = 'rgb(255, 36, 0)';
export const voteGreenColor = 'rgb(11, 222, 35)';

const theme = createTheme({
    palette: {
        primary: {
            main: primaryColor
        },
        secondary: {
            main: primaryColor
        },
        text: {
            primary: "#fff",
            secondary: greyColor
        },
        mode: 'dark'
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true, // No more ripple, on the whole application 💣!
            }
        },
        MuiTooltip: {
            defaultProps: {
                disableFocusListener: true
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    background: "#181818"
                }
            }
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    margin: "auto"
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    margin: 8
                },
                paperScrollPaper: {
                    maxHeight: "calc(100% - 16px)"
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: "12px 24px 12px"
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: "0 24px 12px"
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    // color: primaryColor
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(0, 0, 0, 0.9)",
                    backgroundImage: "none"
                }
            }
        }
    },
    typography: {
        fontFamily: "'Open Sans', sans-serif",
        h1: {
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: "2rem"
        },
        h3: {
            fontSize: "2rem",
            fontWeight: "bold"
        },
        h4: {
            fontSize: "1.35rem"
        },
        subtitle1: {
            textTransform: "uppercase",
            fontWeight: "bold",
            lineHeight: "initial"
        },
        allVariants: {
            color: "#fff"
        }
    }
});

export { theme };

export const snackBarRootTheme = css`
    .SnackbarContent-root {
        background-color: rgba(0, 0, 0, 0.8) !important;
    }
`;