import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { Typography, Link, Fab, Icon } from '@mui/material';
import { HomeContainer } from './HomeView.Components';
import { redirectToLogin } from '../actions';
import { StoreType } from '../types';

interface Props {
    isLoggedIn: boolean;
    history?: any;
}
class HomeViewImpl extends React.PureComponent<Props> {

    render() {
        const { isLoggedIn } = this.props;

        return (
            <HomeContainer className="home">
                <Typography variant="h3" gutterBottom>Welcome!</Typography>
                {!isLoggedIn &&
                    <>
                        <Typography variant="h4" gutterBottom>Click <Link onClick={this.handleLoginClick}>here</Link> to log in</Typography>
                        <Typography>Pre-authorization is required to access the site. Provide your Spotify email to <Link>@mabel</Link> to request access.</Typography>
                    </>
                }
                {isLoggedIn && (
                    <Fab
                        variant="extended"
                        onClick={() => this.props.history.push(`/jukeboxes`)}
                        color="primary"
                    >
                        <Icon>list</Icon>
                        My jukeboxes
                    </Fab>
                )}
            </HomeContainer>
        );
    }

    private handleLoginClick = () => {
        redirectToLogin();
    }
}


const mapStateToProps = (state: StoreType): Props => {
    return {
        isLoggedIn: !!state.account.user.data,
    }
}
export const HomeView = withRouter(connect(mapStateToProps)(HomeViewImpl));