import axios, { AxiosInstance, AxiosError } from 'axios'
import { applyAxiosRetry } from './applyAxiosRetry';

import { JukeboxClient } from './JukeboxClient'
import { SpotifyPlayer } from "./SpotifyPlayer"
import log from "./log";

export class SpotifyRequestHandler {

    private tokenAge: Date;
    private _accessToken: string;

    private axios: AxiosInstance;

    private player: SpotifyPlayer;

    public static create(): Promise<SpotifyRequestHandler> {
        log.debug('SpotifyRequestHandler.create');
        const rh = new SpotifyRequestHandler();

        return SpotifyPlayer.create(rh.getNewAccessToken)
            .then((player) => {
                rh.player = player;
                player.addListener("error", rh.handlePlayerError);
                return rh;
            });
    }

    private constructor() {
        this.axios = axios.create();
        this.axios.defaults.baseURL = 'https://api.spotify.com/v1'
        applyAxiosRetry(this.axios);
        setInterval(this.checkAndUpdateToken, 60 * 1000);
    }

    private setAccessToken(accessToken: string) {
        log.debug("Set new access token", accessToken);
        this.tokenAge = new Date();
        this._accessToken = accessToken;

        // this.axios.defaults.headers = { Authorization: 'Bearer ' + accessToken };
    }

    public disconnect() {
        this.player.disconnect();
    }

    public addListener(event: string | symbol, listener: (...args: any[]) => void) {
        this.player.addListener(event, listener);
    }

    public removeListener(event: string | symbol, listener: (...args: any[]) => void) {
        this.player.removeListener(event, listener);
    }

    private _updateAccessTokenPromise: Promise<any> = null;
    private updateAccessToken = () => {
        if (this._updateAccessTokenPromise) {
            return this._updateAccessTokenPromise;
        }

        log.debug("updating spotify access token");
        this._updateAccessTokenPromise = JukeboxClient.instance.getSpotifyToken()
            .then(t => {
                this.setAccessToken(t.accessToken);
            })
            .catch(err => {
                log.error("Failed to update Spotify token", err);
            })
            .then(() => {
                this._updateAccessTokenPromise = null;
            });

        return this._updateAccessTokenPromise;
    }

    private checkAndUpdateToken = async () => {
        if (!this.tokenAge || (new Date().getTime() - this.tokenAge.getTime() >= 55 * 60 * 1000)) {
            log.debug("Spotify token about to expire, updating");
            await this.updateAccessToken();
        }
    }

    private getNewAccessToken = async (): Promise<string> => {
        // if token is more than 10min old, then update
        if (!this.tokenAge || (new Date().getTime() - this.tokenAge.getTime() >= 10 * 60 * 1000)) {
            await this.updateAccessToken();
        }

        return this._accessToken;
    }

    private handlePlayerError = async (errorMsg: string, error: string) => {
        log.error("Spotify player error", error, errorMsg);
    }

    public getPlaybackInfo() {
        return this.axios.get('me/player', { headers: { Authorization: 'Bearer ' + this._accessToken } })
            .then(resp => {
                if (resp.data.progress_ms === 0) {
                    log.warn("Progress MS is 0", resp.data);
                }

                return resp;
            });
    }

    public getCurrentPlayerState() {
        return this.player.getCurrentState();
    }

    public getPlaylists() {
        return this.axios.get('me/playlists', { headers: { Authorization: 'Bearer ' + this._accessToken } });
    }

    public getPlaylistTracks(tracksUri: string, items: any[] = []) {
        return this.axios.get(tracksUri, {
            params: { market: "from_token" },
            headers: { Authorization: 'Bearer ' + this._accessToken }
        })
        .then(response => {
            const allItems = items.concat(
                response.data.items
                    .filter(item => item.track.is_playable)
            );
            if (!response.data.next)
                return allItems;

            return this.getPlaylistTracks(response.data.next, allItems);
        })
    }

    public async play(trackUri: string = undefined, retry = true): Promise<any> {
        log.debug("play", trackUri);
        if (!trackUri)  {
            return this.player.resume();
        }

        try {
            await this.axios.put('me/player/play',
                {
                    uris: !!trackUri ? [trackUri] : undefined,
                },
                {
                    params: { device_id: this.player.deviceId },
                    headers: { Authorization: 'Bearer ' + this._accessToken }
                }
            );
        }
        catch(e) {
            if (retry) {
                await this.checkServerErrorAndResetPlayer(e);
                await this.play(trackUri, false);
                log.debug("retry successful");
                return;
            }
            throw e;
        }
    }

    public pause() {
        log.debug("pause");
        return this.player.pause();
    }

    private checkServerErrorAndResetPlayer(error: AxiosError) {
        if (error.response && (error.response.status === 404 || error.response.status >= 500)) {
            log.error("spotify server error, resetting player", error.response.status, error.response.statusText, error.response);
            return this.player.reset();
        }

        return Promise.reject(error);
    }

    private readonly SPOTIFY_TRACK_MODIFICATION_LIMIT = 100;

    public replacePlaylistTracks(playlistTracksUri: string, trackIds: Array<string>) {
        const firstHundredTrackIds = trackIds.length <= this.SPOTIFY_TRACK_MODIFICATION_LIMIT ? trackIds : trackIds.slice(0, this.SPOTIFY_TRACK_MODIFICATION_LIMIT - 1);

        return this.axios.put(playlistTracksUri, {
            uris: trackIds
        },
        { headers: { Authorization: 'Bearer ' + this._accessToken } })
        .then((result) => {
            if (firstHundredTrackIds === trackIds)
                return result;

            return this.addPlaylistTracks(playlistTracksUri, trackIds.slice(this.SPOTIFY_TRACK_MODIFICATION_LIMIT));
        })
    }

    public addPlaylistTracks(playlistTracksUri: string, trackIds: Array<string>) {
        const firstHundredTrackIds = trackIds.length <= this.SPOTIFY_TRACK_MODIFICATION_LIMIT ? trackIds : trackIds.slice(0, this.SPOTIFY_TRACK_MODIFICATION_LIMIT - 1);

        return this.axios.post(playlistTracksUri, {
            uris: trackIds,
        },
        { headers: { Authorization: 'Bearer ' + this._accessToken } })
        .then((result) => {
            if (firstHundredTrackIds === trackIds)
                return result;

            return this.addPlaylistTracks(playlistTracksUri, trackIds.slice(this.SPOTIFY_TRACK_MODIFICATION_LIMIT));
        })
    }

    public search(q: string, type: string = 'track', limit: number = 1) {
        return this.axios.get('search', {
            params: {
                q: q,
                limit: limit,
                type: type,
                market: "from_token"
            },
            headers: { Authorization: 'Bearer ' + this._accessToken }
        })
    }

    public getTrack(spotifyUri: string) {
        const pref = "spotify:track:"
        const id = spotifyUri.substr(pref.length);

        return this.axios.get('tracks/' + id, { params: {
            market: "from_token"
        }, headers: { Authorization: 'Bearer ' + this._accessToken } })
        .then(response => response.data);
    }

    public getVolume() {
        return this.player.getVolume();
    }

    public setVolume(volume: number) {
        return this.player.setVolume(volume);
    }
}