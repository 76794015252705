import { Track, JukeboxInfo } from "./types";

const DEFAULT_EXPIRATION_IN_SECONDS = 24 * 60 * 60; // 24 hours

export const isPendingApproval = (track: Track) => !!track.pendingAdd;
export const isNegativeVoteBalance = (track: Track) => track.voters.up.length < track.voters.down.length;
export const hasUpVotes = (track: Track) => track.voters.up.length > 0;
export const isPendingRemoval = (track: Track) => (isPendingApproval(track) && !hasUpVotes(track)) || isNegativeVoteBalance(track);

export const secondsToRemoval = (track: Track, jukebox: JukeboxInfo): number => {
    if (!isPendingRemoval(track))
        return Infinity;

    const timeDiffS = (Date.now() - track.lastVoteTimestamp) / 1000;
    const expiration = jukebox.trackExpirationInSeconds || DEFAULT_EXPIRATION_IN_SECONDS;

    return expiration - timeDiffS;
}