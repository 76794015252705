import * as React from 'react';
import { Typography } from '@mui/material';

import { JukeboxStatus, Tracks, JukeboxInfo } from '../types';
import {
    LeftPaneContainer,
    LeftPaneContent,
} from "./LeftPane.Components";
import { VotingDetails } from './VotingDetails';
import { FlexColumn } from './Common';


export interface Props {
    readonly tracks: Tracks;
    readonly jukebox: JukeboxInfo;
    readonly jukeboxId: string;
}

export class LeftPane extends React.PureComponent<Props, undefined> {

    render() {
        const trackInfo = this.props.jukebox.currentTrack ||
        {
            image: undefined,
            artist: 'N/A',
            title: 'N/A'
        };

        return (
            <LeftPaneContainer className="left-pane">
                <LeftPaneContent className="left-pane-content">
                    <FlexColumn className="jukebox-info">
                        <Typography variant="subtitle1" color="primary" gutterBottom>
                            {this.props.jukebox.playlistName}
                        </Typography>
                        <div>
                            <Typography variant="h1" color="primary" gutterBottom>{this.getNowPlayingText()}</Typography>
                            <Typography variant="h3" gutterBottom>{trackInfo.title}</Typography>
                            <Typography variant="h4" color="textSecondary" gutterBottom>
                                {trackInfo.artist}
                            </Typography>
                        </div>
                    </FlexColumn>
                    <FlexColumn className="voting-info">
                        <VotingDetails jukebox={this.props.jukebox} />
                    </FlexColumn>
                </LeftPaneContent>
            </LeftPaneContainer>
        );
    }

    private getNowPlayingText(): string {
        switch (this.props.jukebox.status) {
            case JukeboxStatus.Running:
                return "Now playing";
            case JukeboxStatus.Paused:
                return "Paused";
            default:
                return "Jukebox not started";
        }
    }

}
