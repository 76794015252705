import { v4 as uuidv4 } from "uuid"
import { CommandProcessor } from './CommandProcessor'
import { SyncController } from './SyncController'
import { SpotifyRequestHandler } from './SpotifyRequestHandler'
import { Track, Tracks, PlaybackInfo, JukeboxStatus, JukeboxInfo, SyncEventType } from './types'

import { store } from './store'
import log from "./log";
import * as Actions from './actions'
import { secondsToRemoval } from './TrackHelper';

const NoSleep = require('nosleep.js');

export const PLAYBACK_VOTE_THRESHOLD_MS = 30000;

enum RecoveryState {
    None,
    Needed,
    Recovering
}

export class JukeboxController {

    private _syncController: SyncController;
    private _spotifyApi: SpotifyRequestHandler;
    private _commandProcessor: CommandProcessor;

    private _setupNextTrackTimer: any;
    private _playbackValidationTimer: any;
    private _removePendingTracksTimer: any;
    private _noSleep = new NoSleep();

    private _playbackRecoveryState = RecoveryState.None;
    private _jukeboxInfoRecoveryState = RecoveryState.None;

    private _controllerId: string = uuidv4();

    public get jukebox(): JukeboxInfo {
        return store.getState().jukebox;
    }

    public get tracks(): Tracks {
        return store.getState().tracks.tracks;
    }

    public get playbackInfo(): PlaybackInfo {
        return store.getState().spotifyState.playbackInfo;
    }

    public get syncController(): SyncController {
        return this._syncController;
    }

    public get spotifyApi(): SpotifyRequestHandler {
        return this._spotifyApi;
    }

    public static create(syncController: SyncController, spotifyApi: SpotifyRequestHandler): Promise<JukeboxController> {
        const jukebox = new JukeboxController(spotifyApi);
        return jukebox.initialize(syncController)
            .then(() => {
                Actions.setJukeboxController(jukebox);
                return jukebox;
            });
    }

    private constructor(spotifyApi: SpotifyRequestHandler) {
        this._spotifyApi = spotifyApi;
        this._spotifyApi.addListener("playbackInfoChanged", this.handlePlaybackInfoChange);

        this._playbackValidationTimer = setInterval(this.handlePlaybackValidationTimer, 10000);
        this._removePendingTracksTimer = setInterval(this.handleRemovePendingTracksTimer, 1 * 60 * 1000);
    }

    public getNewTrackIndex(): number {
        const newIndex = this.jukebox.lastTrackIndex + 1;
        this._syncController.setJukeboxInfo({...this.jukebox, lastTrackIndex: newIndex });

        return newIndex;
    }

    public cleanup() {
        this.stop();
        if (this._syncController) {
            this._syncController.removeListener("disconnected", this.handleSyncDisconnected);
            this._syncController.cleanup();
        }

        Actions.setJukeboxController(null);
    }

    private async initialize(syncController: SyncController): Promise<any> {
        this._syncController = syncController;
        this._syncController.addListener("disconnected", this.handleSyncDisconnected);

        this._commandProcessor = new CommandProcessor();
        await this._commandProcessor.initController(this);

        const syncDoc = syncController.getJukeboxDocument();
        syncDoc.addListener("updated", this.handleJukeboxInfoDocumentChange);
        syncDoc.addListener("removed", this.handleJukeboxInfoDocumentChange);

        const topTrack = JukeboxController.getTopTrackBasedOnVotes(this.tracks);
        await this._syncController.setJukeboxInfo({
            ...this.jukebox,
            upcomingTrack: topTrack,
            status: JukeboxStatus.Ready,
            controllerId: this._controllerId
        });
    }

    private prevJbInfo: JukeboxInfo;
    private prevConnectionLostTime: Date = new Date();
    private handleJukeboxInfoDocumentChange = (args: { data?: JukeboxInfo, isLocal: boolean}) => {
        const jbInfo = args.data;
        if (!jbInfo || (jbInfo.controllerId !== this._controllerId)) {
            log.debug("Controller changed, stopping current controller");
            this.stopControllerAndCleanup();
        }

        const setRecoveryState = (state: RecoveryState) => {
            if (this._jukeboxInfoRecoveryState === RecoveryState.Recovering) {
                log.debug("recovery from auto-stopped was successful");
            }
            this._jukeboxInfoRecoveryState = state;
        }

        if (jbInfo && jbInfo.status === JukeboxStatus.Running) {
            setRecoveryState(RecoveryState.None);
        }

        const timeFromConnectionLostInS = (new Date().getTime() - this.prevConnectionLostTime.getTime()) / 1000;
        const wasRunning = this.prevJbInfo && this.prevJbInfo.status === JukeboxStatus.Running;
        if (wasRunning && jbInfo && jbInfo.status === JukeboxStatus.StoppedAuto &&
            this._controllerId === jbInfo.controllerId &&
            timeFromConnectionLostInS < 300)
        {
            setRecoveryState(RecoveryState.Recovering);
            log.warn("the controller was marked as stopped, but we have the connection now, let's resume");
            // backend marked the jb as stopped while this instance is the running master controller
            if (this.playbackInfo.playing) {
                log.debug("playing music, mark status as running");
                this.setStatus(JukeboxStatus.Running)
                    .catch(err => {
                        log.error("Failed to set JB as Running from auto-stopped", err);
                    });
            }
            else {
                log.debug("not playing music, starting");
                this.start()
                    .catch(err => {
                        log.error("Failed restart JB from auto-stopped state", err);
                    });
            }
        }

        this.prevJbInfo = jbInfo;
    }

    private stopControllerAndCleanup() {
        log.debug("stopController");
        // new controller appeared, do not control anything
        this._spotifyApi.removeListener("playbackInfoChanged", this.handlePlaybackInfoChange);
        this._spotifyApi.disconnect();
        this._commandProcessor.stop();
        const syncDoc = this._syncController.getJukeboxDocument();
        syncDoc.removeListener("updated", this.handleJukeboxInfoDocumentChange);
        syncDoc.removeListener("removed", this.handleJukeboxInfoDocumentChange);

        this.clearAllTimers();
        Actions.setJukeboxController(null);
    }

    private clearAllTimers() {
        log.debug("clearing all timers");
        clearTimeout(this._setupNextTrackTimer);
        clearInterval(this._playbackValidationTimer);
        clearInterval(this._removePendingTracksTimer);
    }

    private _starting = false;
    public async start() {
        log.debug("JukeboxController start");
        const preStatus = this.jukebox.status;
        if (preStatus === JukeboxStatus.Running)
            return;

        if (this._starting) {
            return;
        }
        this._starting = true;

        this._noSleep.enable();

        const prePlayPromises = [];
        if (preStatus !== JukeboxStatus.Paused) {
            prePlayPromises.push(this.setupNextTrack());
        }
        try {
            await Promise.all(prePlayPromises)
                .then(() => {
                    this.setStatus(JukeboxStatus.Running);

                    if (preStatus === JukeboxStatus.Paused) {
                        if (!!this.playbackInfo && !this.playbackInfo.playing)
                            return this._spotifyApi.play();

                        return; // already playing
                    }

                    return this.startUpcomingTrack();
                });
            this._starting = false;
        }
        catch (err) {
            this._starting = false;
            throw err;
        }
    }

    public setupNextTrackAndPlay() {
        if (this.jukebox.status !== JukeboxStatus.Running)
            return;

        const setupIfNeeded = (!!this.jukebox.upcomingTrack) ? Promise.resolve(null) : this.setupNextTrack();
        return setupIfNeeded
            .then(() => this.jukebox.upcomingTrack && this.startUpcomingTrack());
    }

    public async stop() {
        log.debug("JukeboxController stop");
        if (this.jukebox.status === JukeboxStatus.Paused)
            return;

        this.clearAllTimers();
        this._noSleep.disable();
        return this._spotifyApi.pause()
            .then(() => {
                return this.setStatus(JukeboxStatus.Paused);
            });
    }

    private setStatus(status: JukeboxStatus) {
        if (this.jukebox.status === status)
            return;

        return this._syncController.setJukeboxInfo({...this.jukebox, status: status });
    }

    private clearVotesAndPrepareTrackForPlayback(track: Track) {
        if (!track)
            return Promise.reject("No track URI");

        const newTrack = {...track,
            lastVotesUp: track.voters.up.length,
            lastVotesDown: track.voters.down.length,
            lastPlayedTimestamp: new Date().getTime(),
            voters: {
                up: [],
                down: []
            }
        };

        return this._syncController.updateTrack(newTrack.syncIndex, newTrack);
    }

    public static getSortedTracksBasedOnVotes(tracks: Array<Track>): Array<Track> {
        return tracks.sort(JukeboxController.compareTracks);
    }

    public static getTopTrackBasedOnVotes(tracks: Tracks): Track {
        let topTrack = undefined;

        tracks.forEach((v, k) => {
            if (!topTrack) {
                topTrack = v;
            }
            else if (JukeboxController.compareTracks(topTrack, v) > 0) {
                topTrack = v;
            }
        })

        return topTrack;
    }

    private static compareTracks(a: Track, b: Track): number {
        const votesA = a.voters.up.length + a.voters.down.length;
        const votesB = b.voters.up.length + b.voters.down.length;
        let d = (b.voters.up.length - b.voters.down.length) - (a.voters.up.length - a.voters.down.length);
        if (d === 0)
            d = b.voters.up.length - a.voters.up.length;
        if (d === 0)
            d = Number(a.pendingAdd || false) - Number(b.pendingAdd || false);
        if (d === 0 && votesA > 0 && votesB > 0)
            d = a.lastVoteTimestamp - b.lastVoteTimestamp;
        if (d === 0)
            d = a.lastPlayedTimestamp - b.lastPlayedTimestamp;
        if (d === 0)
            d = a.jukeboxIndex - b.jukeboxIndex;

        return d;
    }

    private handleSyncDisconnected = () => {
        this.prevConnectionLostTime = new Date();
        log.warn("Sync disconnected, stopping JB");
        this.stop()
            .catch(e => {
                log.debug("failed to stop JB after disconnect");
            });
    }

    private ensureSetupNextTrackTimer(pi: PlaybackInfo) {
        if (!pi.playing) {
            return;
        }

        if (this.jukebox.status !== JukeboxStatus.Running) {
            return;
        }

        if (this.jukebox.upcomingTrack) {
            return;
        }

        const timeoutMs = Math.max(pi.durationMs - pi.progressMs - PLAYBACK_VOTE_THRESHOLD_MS, 1);
        log.debug("Setting timer to choose next track in", timeoutMs, "ms");
        clearTimeout(this._setupNextTrackTimer);

        this._setupNextTrackTimer = setTimeout(() => {
            if (this.jukebox.status === JukeboxStatus.Running) {
                log.debug("Set timer to fix next track");
                this.setupNextTrack();
            }
            this._setupNextTrackTimer = 0;
        }, timeoutMs);
    }

    private handlePlaybackInfoChange = (state: PlaybackInfo) => {
        this.ensureSetupNextTrackTimer(state);
        this.setJukeboxStateFromPlaybackInfo(state);
    }

    private setJukeboxStateFromPlaybackInfo = (playbackInfo: PlaybackInfo) => {
        log.debug("updateJukeboxStateFromPlaybackState", playbackInfo);
        // if JB is running but playback is not, then try to set up next track
        if (this.jukebox.status === JukeboxStatus.Running) {
            // sometimes spotify just fails, and we're left with empty track
            if (!playbackInfo.playing) {
                // jb has been paused, valid track still is there
                if (playbackInfo.track.spotifyId) {
                    // time to start next track, progress is 0
                    if (playbackInfo.progressMs === 0) {
                        log.debug("JB paused with 0 position, starting next track");
                        return this.startUpcomingTrack()
                            .catch(err => {
                                log.error("Failed to start upcoming track. Stopping.", err);
                                this.stop();
                                throw err;
                            });
                    }
                    // progress and duration delta is less than 1s, most likely there will be one more update with progress 0
                    else if (playbackInfo.durationMs - playbackInfo.progressMs < 1000) {
                        log.debug("Not pausing JB yet, duration and progress delta is less than 1s");
                    }
                    // track paused, let's stop JB
                    else {
                        log.debug("Setting JB as paused")
                        return this.stop();
                    }
                }
                else {
                    log.warn("Playback state is null, trying to recover in next validation timer");
                }
            }
        }
        else if (this.jukebox.status === JukeboxStatus.Paused) {
            // RESUMED PLAYBACK, mark jb as running
            if (playbackInfo && playbackInfo.playing) {
                log.debug("Starting JB again");
                return this.start();
            }
        }
    }

    private _lastTrackToStart: Track;
    private async startUpcomingTrack() {
        if (!this.jukebox.upcomingTrack) {
            log.debug("no upcoming track to start");
            return;
        }

        if (this._lastTrackToStart === this.jukebox.upcomingTrack) {
            log.debug("already started upcoming track, ignoring", this.jukebox.upcomingTrack);
            return;
        }

        this._lastTrackToStart = this.jukebox.upcomingTrack;
        log.debug('starting next track', this.jukebox.upcomingTrack);
        return this._spotifyApi.play(this.jukebox.upcomingTrack.spotifyId)
            .then(() => {
                const newJukeboxInfo = {...this.jukebox, currentTrack: this.jukebox.upcomingTrack, upcomingTrack: undefined };
                return this._syncController.setJukeboxInfo(newJukeboxInfo);
            })
            .catch(err => {
                log.debug("startNextTrack failed, clearing _lastTrackToStart");
                this._lastTrackToStart = undefined;
                throw err;
            });
    }

    private async setupNextTrack(): Promise<Track> {
        log.debug("setting up next track");
        const topTrack = await this.getPlayableTopTrack();
        log.debug("next track is", topTrack);

        const newJukeboxInfo = {...this.jukebox, upcomingTrack: topTrack };
        return this._syncController.setJukeboxInfo(newJukeboxInfo)
            .then(() => {
                return this._syncController.sendEvent({
                    trackSyncIndex: topTrack.syncIndex,
                    type: SyncEventType.NextTrack
                });
            })
            .then(() => this.clearVotesAndPrepareTrackForPlayback(topTrack))
            .then(() => topTrack);
    }


    private handlePlaybackValidationTimer = () => {
        this.ensureSpotifyPlayback()
    }

    private async ensureSpotifyPlayback() {

        const isPlayerResponding = async (): Promise<boolean> => {
            let timedOut = false;
            const timeoutPromise = new Promise((resolve) => setTimeout(resolve, 1000))
                .then(() => {
                    timedOut = true;
                });

            await Promise.race([timeoutPromise, this.spotifyApi.getCurrentPlayerState()]);
            if (timedOut) {
                log.warn("Spotify player is not responding");
            }

            return !timedOut;
        }

        const checkNeedsRecovery = async (): Promise<boolean> => {
            const recoveryNeeded = this.jukebox.status === JukeboxStatus.Running && this.playbackInfo
                && (!this.playbackInfo.playing || !await isPlayerResponding());

            if (!recoveryNeeded) {
                this._playbackRecoveryState = RecoveryState.None;
            }

            return recoveryNeeded;
        }

        if (!await checkNeedsRecovery()) {
            return;
        }

        switch (this._playbackRecoveryState) {
            case RecoveryState.None: {
                this._playbackRecoveryState = RecoveryState.Needed; // check again in 10s
                return;
            }
            case RecoveryState.Needed: {
                this._playbackRecoveryState = RecoveryState.Recovering;
                log.error("Player is not playing, trying to restart playback");
                try {
                    if (this.jukebox.upcomingTrack) {
                        await this.startUpcomingTrack();
                    }
                    else if (this._lastTrackToStart) {
                        await this.spotifyApi.play(this._lastTrackToStart.spotifyId);
                    }
                    this._playbackRecoveryState = RecoveryState.None; // successfully recovered
                }
                catch (e) {
                    log.error("Failed to restart playback, stopping jukebox");
                    return this.stop();
                }

                break;
            }
            case RecoveryState.Recovering: {
                this._playbackRecoveryState = RecoveryState.None;
                log.error("Failed to recover playback, stopping jukebox");
                return this.stop();
            }
        }
    }

    private async getPlayableTopTrack(): Promise<Track> {
        let tracks = this.tracks;

        for (let i = 0; i < this.tracks.length; i++) {
            const topTrack = JukeboxController.getTopTrackBasedOnVotes(tracks);
            const potentialTrack = await this.spotifyApi.getTrack(topTrack.spotifyId)
            // this tracks is not playable, remove from playlist, proceed to next
            log.debug("got potential track", potentialTrack);
            if (!potentialTrack || !potentialTrack.is_playable) {
                log.warn("track is not playable, removing from playlist", topTrack.spotifyId);
                await this.syncController.removeTrack(topTrack.syncIndex);

                tracks = tracks.filter(item => item !== topTrack);
            }
            // is playable but with different uri, update track id
            else if (potentialTrack && potentialTrack.uri !== topTrack.spotifyId) {
                const updatedTrack = {
                    ...topTrack,
                    spotifyId: potentialTrack.uri
                };

                await this.syncController.updateTrack(updatedTrack.syncIndex, updatedTrack);

                return updatedTrack;
            }
            // potential tracks is playable, uri matches
            else {
                return topTrack;
            }
        }
    }

    private handleRemovePendingTracksTimer = () => {
        const jb = this.jukebox;
        if (jb.status !== JukeboxStatus.Running)
            return;

        const tracksToRemove = this.tracks.filter(track => secondsToRemoval(track, jb) <= 0);
        tracksToRemove.forEach(async track => {
            log.debug("removing track", track.artist, track.title, track);
            await this._syncController.sendEvent({
                trackSyncIndex: track.syncIndex,
                type: SyncEventType.RemovedTrack
            });
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.syncController.removeTrack(track.syncIndex);
        });
    }
}
