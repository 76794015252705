import * as React from 'react'
import { withRouter } from 'react-router'

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import { Tooltip, Icon } from '@mui/material';
import * as Actions from '../actions';

interface Props {
    readonly history: any;
    readonly isLoggedIn: boolean;
}

class PopupMenuImpl extends React.PureComponent<Props, any> {

    state: any = {};

    render() {
        const { anchorEl } = this.state;

        return (
            <div>
                <Tooltip title="Open menu" aria-label="Open menu">
                    <IconButton
                        aria-owns={!!anchorEl ? 'menu-appbar' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                        color="primary"
                    >
                        <Icon>menu</Icon>
                    </IconButton>
                </Tooltip>
                <Menu
                    color="primary"
                    anchorEl={anchorEl}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={!!anchorEl}
                    onClose={this.handleClose}
                >
                    <MenuItem onClick={this.onMyJukeboxesClick} disabled={!this.props.isLoggedIn}>My Jukeboxes</MenuItem>
                    <MenuItem onClick={this.onCreateJukeboxClick} disabled={!this.props.isLoggedIn} >Create Jukebox</MenuItem>
                    {/* <MenuItem onClick={this.onMyAccountClick} disabled={!this.props.isLoggedIn} >Account</MenuItem> */}
                    <MenuItem onClick={this.signOutClick} disabled={!this.props.isLoggedIn} >Sign out</MenuItem>
                </Menu>
            </div>

        )
    }

    private handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    private handleClose = () => {
        this.setState({ anchorEl: null });
    };

    private onMyJukeboxesClick = () => {
        this.props.history.push('/jukeboxes');
        this.handleClose();
    }

    private onCreateJukeboxClick = () => {
        this.props.history.push('/setup');
        this.handleClose();
    }

    // private onMyAccountClick = () => {
    //     this.props.history.push('/account');
    // }

    private signOutClick = async () => {
        this.handleClose();
        await Actions.logOut()
    }
}

const PopupMenu = withRouter(PopupMenuImpl);
export { PopupMenu };