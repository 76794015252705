import { createStore, applyMiddleware, compose, Reducer, combineReducers } from 'redux'
import promiseMiddleware from 'redux-promise-middleware';
import { createLogger } from 'redux-logger'
import logLevel from "./log";

import { StoreType } from "./types"
import { JukeboxInfoReducer, TracksReducer, SpotifyReducer, AccountReducer, AppReducer } from './reducers'

export const reducer: Reducer<StoreType> = combineReducers<StoreType>({
    app: AppReducer,
    account: AccountReducer,
    jukebox: JukeboxInfoReducer,
    spotifyState: SpotifyReducer,
    tracks: TracksReducer
});

const logger = createLogger({ logger: logLevel });

const store = createStore<StoreType, any, any, any>(
    reducer,
    compose(
        applyMiddleware(promiseMiddleware, logger as any)
    )
);

export { store };