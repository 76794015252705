import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import log from './log';

if (!window.location.host.match(/localhost/)) {
    // patch logger for sentry. Will actually patch the loglevel logger
    const origConsole = console;
    // eslint-disable-next-line no-global-assign
    (console as any) = log;
    Sentry.init({
        dsn: "https://75f63ef9d4ec4c67983eca46d0ae306c@sentry.io/1542232",
        integrations: [
            new CaptureConsole({
                levels: ['error']
            })
        ],
        environment: getEnvFromUrl()
    });
    // eslint-disable-next-line no-global-assign
    console = origConsole;
}

function getEnvFromUrl() {
    const { host } = window.location;
    const match = host.match(/dev|stage/);
    if (match) {
        return match[0];
    }
    return 'prod';
}